/* @charset "UTF-8"; */
.login-page body {
  background: #f3f8fb;
  color: #666666;
  font-family: "RobotoDraft", "Roboto", sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Pen Title */
.login-page .pen-title {
  padding: 40px 0;
  text-align: center;
  letter-spacing: 2px;
}
.login-page .pen-title h1 {
  margin: 0 0 20px;
  font-size: 48px;
  font-weight: 300;
}
.login-page .pen-title span {
  font-size: 12px;
}
.login-page .pen-title span .fa {
  color: #06a180;
}
.login-page .pen-title span a {
  color: #06a180;
  font-weight: 600;
  text-decoration: none;
}

/* Rerun */
.login-page .rerun {
  margin: 0 0 30px;
  text-align: center;
}
.login-page .rerun a {
  cursor: pointer;
  display: inline-block;
  background: #06a180;
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 10px 20px;
  color: #ffffff;
  text-decoration: none;
  transition: 0.3s ease;
}
.login-page .rerun a:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

/* Scroll To Bottom */
.login-page #codepen,
.login-page #portfolio {
  position: fixed;
  bottom: 30px;
  right: 30px;
  background: #363636;
  width: 56px;
  height: 56px;
  border-radius: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: 0.3s ease;
  color: #ffffff;
  text-align: center;
}
.login-page #codepen i,
.login-page #portfolio i {
  line-height: 56px;
}
.login-page #codepen:hover,
.login-page #portfolio:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

/* CodePen */
.login-page #portfolio {
  bottom: 96px;
  right: 36px;
  background: #06a180;
  width: 44px;
  height: 44px;
  -webkit-animation: buttonFadeInUp 1s ease;
  animation: buttonFadeInUp 1s ease;
}
.login-page #portfolio i {
  line-height: 44px;
}

/* Container */
.login-page .container {
  position: relative;
  max-width: 460px;
  width: 100%;
  margin: 0 auto 100px;
}
.login-page .container.active .card:first-child {
  background: #f2f2f2;
  margin: 0 15px;
}
.login-page .container.active .card:nth-child(2) {
  background: #fafafa;
  margin: 0 10px;
}
.login-page .container.active .card.alt {
  top: 20px;
  right: 0;
  width: 100%;
  min-width: 100%;
  height: auto;
  border-radius: 5px;
  padding: 60px 0 40px;
  overflow: hidden;
}
.login-page .container.active .card.alt .toggle {
  position: absolute;
  top: 40px;
  right: -70px;
  box-shadow: none;
  transform: scale(10);
  transition: transform 0.3s ease;
}
.login-page .container.active .card.alt .toggle:before {
  content: "";
}
.login-page .container.active .card.alt .title,
.login-page .container.active .card.alt .input-container,
.login-page .container.active .card.alt .button-container {
  left: 0;
  opacity: 1;
  visibility: visible;
  transition: 0.3s ease;
}
.login-page .container.active .card.alt .title {
  transition-delay: 0.3s;
}
.login-page .container.active .card.alt .input-container {
  transition-delay: 0.4s;
}
.login-page .container.active .card.alt .input-container:nth-child(2) {
  transition-delay: 0.5s;
}
.login-page .container.active .card.alt .input-container:nth-child(3) {
  transition-delay: 0.6s;
}
.login-page .container.active .card.alt .button-container {
  transition-delay: 0.7s;
}

/* Card */
.login-page .card {
  position: relative;
  background: #ffffff;
  border-radius: 5px;
  padding: 45px 0 40px 0;
  box-sizing: border-box;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: 0.3s ease;
  /* Title */
  /* Inputs */
  /* Button */
  /* Footer */
  /* Alt Card */
}
.login-page .card:first-child {
  background: #fafafa;
  height: 10px;
  border-radius: 5px 5px 0 0;
  margin: 0 10px;
  padding: 0;
}
.login-page .card .title {
  position: relative;
  z-index: 1;
  border-left: 5px solid #06a180;
  margin: 0 0 35px;
  padding: 10px 0 10px 50px;
  color: #06a180;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
}
.login-page .card .input-container {
  position: relative;
  margin: 0 60px 50px;
}
.login-page .card .input-container input {
  outline: none;
  z-index: 1;
  position: relative;
  background: none;
  width: 100%;
  height: 45px;
  border: 0;
  color: #212121;
  font-size: 20px;
  font-weight: 400;
}
.login-page .card .input-container input:focus ~ label {
  color: #9d9d9d;
  transform: translate(-12%, -70%) scale(0.75);
}
.login-page .card .input-container input:focus ~ .bar:before,
.login-page .card .input-container input:focus ~ .bar:after {
  width: 50%;
}
.login-page .card .input-container input:not(:placeholder-shown) ~ label {
  color: #9d9d9d;
  transform: translate(-12%, -70%) scale(0.75);
}
.login-page .card .input-container label {
  position: absolute;
  top: 0;
  left: 0;
  color: #757575;
  font-size: 15px;
  font-weight: 300;
  line-height: 60px;
  transition: 0.2s ease;
}
.login-page .card .input-container .bar {
  /* position: absolute; */
  left: 0;
  bottom: 0;
  background: #757575;
  width: 100%;
  height: 1px;
}
.login-page .card .input-container .bar:before,
.login-page .card .input-container .bar:after {
  content: "";
  position: absolute;
  background: #06a180;
  width: 0;
  height: 2px;
  transition: 0.2s ease;
}
.login-page .card .input-container .bar:before {
  left: 50%;
}
.login-page .card .input-container .bar:after {
  right: 50%;
}
.login-page .card .button-container {
  margin: 0 60px;
  /* text-align: center; */
}
.login-page .card .button-container button {
  outline: 0;
  cursor: pointer;
  position: relative;
  display: inline-block;
  background: #06a180;
  width: 90px;
  border: 2px solid #06a180;
  padding: 7px 0;
  font-size: 15px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  overflow: hidden;
  transition: 0.3s ease;
  border-radius: 5px;
}
.login-page .card .button-container button span {
  position: relative;
  z-index: 1;
  color: #fff;
  transition: 0.3s ease;
}
.login-page .card .button-container button:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  background: #06a180;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  margin: -15px 0 0 -15px;
  opacity: 0;
  transition: 0.3s ease;
}
.login-page .card .button-container button:hover,
.login-page .card .button-container button:active,
.login-page .card .button-container button:focus {
  border-color: #fff;
}
.login-page .card .button-container button:hover span,
.login-page .card .button-container button:active span,
.login-page .card .button-container button:focus span {
  color: #fff;
}
.login-page .card .button-container button:active span,
.login-page .card .button-container button:focus span {
  color: #ffffff;
}
.login-page .card .button-container button:active:before,
.login-page .card .button-container button:focus:before {
  opacity: 1;
  transform: scale(10);
}
.login-page .card .footer {
  margin: 40px 0 0;
  color: #757575;
  font-size: 15px;
  font-weight: 300;
  text-align: center;
}
.login-page .card .footer a {
  color: inherit;
  text-decoration: none;
  transition: 0.3s ease;
}
.login-page .card .footer a:hover {
  color: #bababa;
}
.login-page .card.alt {
  position: absolute;
  top: 40px;
  right: -35px;
  z-index: 10;
  width: 85px;
  height: 85px;
  background: none;
  border-radius: 100%;
  box-shadow: none;
  padding: 0;
  transition: 0.3s ease;
  /* Toggle */
  /* Title */
  /* Input */
  /* Button */
}
.login-page .card.alt .toggle {
  position: relative;
  background: #06a180;
  width: 85px;
  height: 85px;
  border-radius: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  color: #ffffff;
  font-size: 30px;
  line-height: 85px;
  text-align: center;
  cursor: pointer;
}
.login-page .card.alt .toggle:before {
  content: "";
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
}
.login-page .card.alt .title,
.card.alt .input-container,
.card.alt .button-container {
  left: 100px;
  opacity: 0;
  visibility: hidden;
}
.login-page .card.alt .title {
  position: relative;
  border-color: #ffffff;
  color: #ffffff;
}
.login-page .card.alt .title .close {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 60px;
  display: inline;
  color: #ffffff;
  font-size: 58px;
  font-weight: 400;
}
.login-page .card.alt .title .close:before {
  content: "×";
}
.login-page .card.alt .input-container input {
  color: #ffffff;
}
.login-page .card.alt .input-container input:focus ~ label {
  color: #ffffff;
}
.login-page .card.alt .input-container input:focus ~ .bar:before,
.login-page .card.alt .input-container input:focus ~ .bar:after {
  background: #ffffff;
}
.login-page .card.alt .input-container input:valid ~ label {
  color: #ffffff;
}
.login-page .card.alt .input-container label {
  color: rgba(255, 255, 255, 0.8);
}
.login-page .card.alt .input-container .bar {
  background: rgba(255, 255, 255, 0.8);
}
.login-page .card.alt .button-container button {
  width: 100%;
  background: #ffffff;
  border-color: #ffffff;
}
.login-page .card.alt .button-container button span {
  color: #06a180;
}
.login-page .card.alt .button-container button:hover {
  background: rgba(255, 255, 255, 0.9);
}
.login-page .card.alt .button-container button:active:before,
.login-page .card.alt .button-container button:focus:before {
  display: none;
}

/* Keyframes */
@-webkit-keyframes buttonFadeInUp {
  0% {
    bottom: 30px;
    opacity: 0;
  }
}
@keyframes buttonFadeInUp {
  0% {
    bottom: 30px;
    opacity: 0;
  }
}
