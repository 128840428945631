@media print {
  body *,
  .visible {
    visibility: hidden !important;
  }
  #card,
  #card * {
    visibility: visible !important;
  }
  /* #card {
      position: absolute;
      left: 0;
      top: 0;
    } */
}
