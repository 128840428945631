/* Sidebar */
.sidebar-wrapper .metismenu li + li {
  margin-top: 5px;
}

.sidebar-wrapper .metismenu li:first-child {
  margin-top: 5px;
}

.sidebar-wrapper .metismenu li:last-child {
  margin-bottom: 5px;
}

.sidebar-wrapper .metismenu > li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
}

.sidebar-wrapper .metismenu a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 10px 15px;
  border-radius: 4px;
  font-size: 15px;
  color: #5f5f5f;
  outline-width: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  letter-spacing: 0.5px;
  border-left: 0px solid rgb(255 255 255 / 0%);
  /* transition: all 0.2s ease-out; */
}

.sidebar-wrapper .metismenu a .parent-icon {
  font-size: 18px;
  line-height: 1;
}

.sidebar-wrapper .metismenu a .menu-title {
  margin-left: 10px;
}

.sidebar-wrapper .metismenu ul a {
  padding: 6px 15px 6px 15px;
  font-size: 15px;
  border: 0;
  border-left: 4px solid rgb(255 255 255 / 0%);
}

.sidebar-wrapper .metismenu ul a i {
  margin-right: 10px;
  font-size: 10px;
}

.sidebar-wrapper .metismenu ul {
  border: 1px solid #ffffff00;
  background: rgb(255 255 255 / 0%);
}

.sidebar-wrapper .metismenu ul ul a {
  padding: 8px 15px 8px 30px;
}

.sidebar-wrapper .metismenu ul ul ul a {
  padding: 8px 15px 8px 45px;
}

.sidebar-wrapper .metismenu .mm-active > a,
.sidebar-wrapper .metismenu .mm-active ul a.active,
.sidebar-wrapper .metismenu a:active,
.sidebar-wrapper .metismenu a:focus,
.sidebar-wrapper .metismenu a:hover {
  color:#06a180;
  text-decoration: none;
  background-color: rgb(255 255 255);
  /* border-left: 4px solid#06a180; */
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
}
.chart-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  height: 100%;
}
.form-check-input-1 {
  width: 20px;
  height: 20px;
}
.card-2 {
  padding: 35px;
  background: white;
  min-height: 700px;
}
.cart-caption {
  font-size: 32px;
  font-weight: 700;
  padding: 0;
  margin-bottom: 15px;
}
.cart-sticky {
  position: sticky;
  top: 80px;
}
.card-1 {
  margin-top: 75px;
  padding: 35px;
  background: white;
  min-height: 700px;
}
.cart-entry-finance {
  background: #06a18014;
  color: #06a180 ;
  padding: 30px;
  border-radius: 5px;
}
.cart-entry-finance span {
  font-weight: 700;
  float: right;
  font-size: 18px;
}
.outer-pie {
  margin: 0 auto !important;
  width: 500px;
  height: 450px;
}
.pay-btn {
  background: #e1dfdd;
  padding: 3px 6px 3px 6px;
  border-radius: 5px;
  /* color: #68625b; */
}

.dailysales-heading {
  font-size: 20px;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-weight: 600;
}
.no-record-message {
  font-size: 18px;
  color:#06a180;
}
.notification-box-1 {
  transition: transform 0.3s ease;
  transform: translateX(100%);
}

.notification-text {
  width: calc(100% - 48px - 15px);
  margin-left: 15px;
  white-space: break-spaces;
}
.cart-entry-1 {
  background: #06a18014;
  color: #06a180 ;
  padding: 10px;
  border-radius: 5px;
}
.cart-entry-1 span {
  font-weight: 700;
  float: right;
  font-size: 18px;
  padding: 10px;
}
.sales-heading {
  background: black;
  width: 150px;
  color: white;
  padding: 5px 10px 5px 10px;
  text-align: center;
  border-radius: 5px;
}
.filter-label {
  font-weight: 600;
  font-size: 17px;
}
.expense-icon {
  padding: 10px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background: #06a18014;
}
.card-text-3 {
  background: #06a18014;
  padding: 5px 12px 5px 12px;
  width: 165px;
  border-radius: 5px;
  color: #06a180 ;
  font-size: 15px;
  font-weight: 700;
}
.custom-col {
  border-right: 1px solid #d2d2d2;
}
.card-text-1 {
  font-size: 12px;
  font-weight: 600;
  color: gray;
}
.card-text-2 {
  font-size: 30px;
  font-weight: 700;
  color: #06a180 ;
}
.card-heading-1 h4 {
  font-size: 20px;
  font-weight: 600;
}
.custom-widget-icon-2 {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  border: 2px solid #fd00001f;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
}
.custom-widget-icon {
  width: 100px;
  height: 100px;
  background-color: #fafafa;
  border-radius: 50%;
  border: 2px solid #9b9b9b1f;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
}
.active-btn-2 {
  border: 0;
  border-radius: 5px;
  background: #e7f7ea !important;
  padding: 3px 10px 3px 10px;
}
.inactive-btn-2 {
  border: 0;
  border-radius: 5px;
  background: #f4e6eb;
  padding: 3px 10px 3px 10px;
}
.recieve-btn {
  background: #f4e6eb !important;
  padding: 4px 19px 4px 19px;
  border-radius: 5px;
}
.block-btn {
  border: 0;
  border-radius: 5px;
  background: #f4e6eb;
  padding: 0px 12px 0px 12px;
}
.print-btn {
  background: #fff6ec;
  padding: 3px 6px 3px 6px;
  border-radius: 5px;
  color: #e0790f;
}
.active-btn {
  border: 0;
  border-radius: 5px;
  background: #e7f7ea !important;
}

.adj-hight {
  height: 320px;
  overflow: auto;
}
.adj-hight .table-style-1 {
  margin-bottom: 0 !important;
}
.adj-hight .table-style-1 thead th {
  position: sticky;
  top: 0;
}
.adj-hight .total-fot {
  position: sticky;
  bottom: 0;
}

.inactive-btn {
  border: 0;
  border-radius: 5px;
  background: #f4e6eb;
}
.inative-text {
  font-size: 13px;
}
.max-cafeitemcart {
  max-height: 313px;
  overflow: auto;
}

.max-cafeitemcart::-webkit-scrollbar {
  width: 3px;
}

.max-cafeitemcart::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.max-cafeitemcart::-webkit-scrollbar-thumb {
  background: #888;
}

.max-cafeitemcart::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.updt-btn {
  background: #e7f7ea !important;
  padding: 2px 7px 2px 7px;
  border-radius: 5px;
}
button.updt-btn:disabled,
.inactive-btn:disabled {
  border: 0;
  opacity: 0.7;
}

.updt-text {
  font-size: 12px;
}
.image-for-table {
  border-radius: 50%;
  height: 45px;
  width: 45px;
  /* border: 1px solid #4c5258; */
  overflow: hidden;
}
.custom-iamge-fortable {
  width: 50px;
}
.custom-role-text {
  font-size: 11px;
  margin-top: 3px;
}
.custom-table-row {
  padding: 30px;
}
.custom-group .custom-form-control {
  padding: 6px 10px;
  height: 35px !important;
  border-radius: 2px;
  border: 1px solid #e3e1e1;
  font-size: 14px;
}
.disable-button1 {
  border: 0;
  background: #fff2cf !important;
  color: #000;
  padding: 4px 70px 4px 70px !important;
  margin-top: 4px;
  margin-left: 7px;
  border-radius: 5px;
  font-weight: 400;
}
.custom-export-btn {
  padding: 6px 7px 6px 7px;
}
.print-button {
  border: none;
  background: green;
  color: #fff;
  border-radius: 5px;
  padding-bottom: 5px;
  padding-top: 5px;
}
.custom-btn-danger {
  border-radius: 2px;
  background-color:#06a180;
  color: #fff;
}
 
.active-text {
  color: #303030;
}
.card {
  margin-bottom: 1.5rem;
}

a {
  color:#06a180;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}
.page-link {
  position: relative;
  font-size: 14px;
  display: block;
  padding: 0.375rem 0.75rem;

  color:#06a180;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color:#06a180;
  border-color:#06a180;
}

.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  background-color:#06a180;
  border-color:#06a180;
  color: white !important;
}

.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  border-color:#06a180;
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #303030;
  border-color: #303030;
  opacity: 0.7;
}

button.btn.d-inline-block.btn-sm.custom-action-button.show,
button.btn.d-inline-block.btn-sm.custom-action-button:focus,
button.btn.d-inline-block.btn-sm.custom-action-button:active {
  border: 0;
  background: transparent;
}
.instalmnt-info {
  display: none;
}
.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color:#06a180;
}

.custom-dropdown {
  background-color: #f3f8fb;
}
.PhoneInputInput {
  border: none;
}
.action-dropdown-data {
  display: none;
  cursor: pointer;
}
.action-dropdown:hover .action-dropdown-data {
  display: block;
  border-radius: 5px;
}
.custom-model-button {
  border: none;
  border-radius: 2px;
  background: #000;
  padding: 7px 13px 7px 13px;
}
.btn-close {
  background: none;
}
.custom-model-icon {
  background-image: url(../public/assets/images/close-btn.png);
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  opacity: inherit;
}
/* General */

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");

body {
  font-size: 14px;
  color: #4c5258;
  letter-spacing: 0.5px;
  /*font-family: Roboto, sans-serif;*/
  font-family: "Open Sans", sans-serif;
  background-color: #f3f8fb;
  overflow-x: hidden;
}

.wrapper {
  width: auto;
  position: relative;
}

a {
  text-decoration: none;
}

::placeholder {
  opacity: 0.4 !important;
}
.custom-update-btn {
  text-decoration: none;
  background:#06a180;
  color: white;
  padding: 2px 5px 2px 5px !important;
}
.custom-block-button {
  border: none;
  border-radius: 5px;
  padding: 5px 13px 5px 16px;
  background:#06a180;
}
.custom-update-button {
  border: none;
  border-radius: 5px;
  padding: 5px 25px 5px 25px !important;
  background: #dcd9d9 !important;
  color: #000;
}
.custom-delete-button {
  border: none;
  border-radius: 5px;
  padding: 5px 16px 5px 16px !important;
}
.custom-unblock-button {
  border: none;
  border-radius: 5px;
  padding: 5px 7px 5px 5px;
}
.custom-active-button {
  padding: 2px 15px 2px 15px !important;
  border: none;
  border-radius: 5px;
  background: #e7f7ea !important;
  color: #498549;
  font-weight: 500;
}
.custom-inactive-button {
  border: none;
  border-radius: 5px;
  padding: 5px 18px 5px 18px;
}

.custom-button {
  border: none;
  border-radius: 5px;
}
.custom-button1 {
  border: none;
  background-color: #000;
  color: #fff;
  padding: 7px 15px;
  border-radius: 5px;
}
.custom-button1:hover {
  background-color: #06a180 ;
}
.top-arrow {
  margin-left: 1px;
}
.custom-toast {
  background: blue;
}
/* Header */
.top-header .navbar {
  position: fixed;
  top: 0;
  left: 260px;
  right: 0;
  background-color: #ffffff;
  border-bottom: 1px solid #d2d4d7;
  background-clip: padding-box;
  height: 60px;
  z-index: 1001;
  padding: 0 0.7rem;
}
.top-header .navbar .top-navbar-right .nav-link {
  color: #293445;
  font-size: 18px;
  font-weight: 500;
  padding-right: 0.8rem;
  padding-left: 0.8rem;
}
.top-header .navbar .messages,
.top-header .navbar .notifications {
  position: relative;
}
.top-header .navbar .notifications {
  width: fit-content;
  margin: 0 auto;
}

.dropdown-toggle-nocaret:after {
  display: none;
}

.top-header .navbar .dropdown-menu {
  -webkit-animation: 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1
    animdropdown;
  animation: 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1
    animdropdown;
}

@-webkit-keyframes animdropdown {
  from {
    -webkit-transform: translate3d(0, 6px, 0);
    transform: translate3d(0, 6px, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes animdropdown {
  from {
    -webkit-transform: translate3d(0, 6px, 0);
    transform: translate3d(0, 6px, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

/* webcam responsive  */
@media only screen and (max-width: 374px) {
  .webcam {
    width: 208px;
  }
}
@media only screen and (max-width: 424px) {
  .webcam {
    width: 360px;
  }
}
@media only screen and (max-width: 377px) {
  .webcam {
    width: 310px;
  }
}
@media only screen and (max-width: 769px) {
  .webcam {
    width: 330px;
    margin: 0 auto;
    display: block;
  }
}

/* ``````````````````````````````````````````````````````` */
.top-header .navbar .dropdown-large,
.dropdown-user-setting .navbar .dropdown-large {
  position: relative;
}

.dropdown-large .dropdown-menu {
  width: 360px;
  border: 0;
  padding: 0.5rem;
  border-radius: 10px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.top-header .navbar .dropdown-large .dropdown-menu::after {
  content: "";
  width: 13px;
  height: 13px;
  background: #fff;
  position: absolute;
  top: -6px;
  right: 16px;
  transform: rotate(45deg);
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
}

.top-header .navbar .dropdown-menu::after {
  content: "";
  width: 13px;
  height: 13px;
  background: #ffff;
  position: absolute;
  top: -6px;
  right: 16px;
  transform: rotate(45deg);
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
}

.top-header .navbar .dropdown-menu::after {
  content: "";
  width: 13px;
  height: 13px;
  background: #ffff;
  position: absolute;
  top: -6px;
  right: 16px;
  transform: rotate(45deg);
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
}

.top-header .navbar .dropdown-item {
  padding: 0.5rem;
}

.top-header .navbar .dropdown-large .dropdown-item:hover {
  border-radius: 10px;
  color: #1e2125;
  background-color: #f5f5f5;
}

header.top-header.customer-header
  nav.navbar.navbar-expand-lg
  ul.navbar-nav
  li.nav-item
  .nav-link
  I {
  display: block;
  font-size: 15px;
}
header.top-header.customer-header
  nav.navbar.navbar-expand-lg
  ul.navbar-nav
  li.nav-item
  .nav-link {
  border-top: 0px solid #b1b5ba;
  border-bottom: 0px solid #9ea1a7;
  border-right: 1px solid #d2d4d7;
  border-left: none;
  color: #000000;
  text-align: center;
  padding: 15px 20px;
}
header.top-header.customer-header
  nav.navbar.navbar-expand-lg
  ul.navbar-nav
  li.nav-item:first-child
  > .nav-link {
  border-left: 1px solid #d2d4d7;
}

header.top-header.customer-header
  nav.navbar.navbar-expand-lg
  ul.navbar-nav
  li.nav-item
  .nav-link:hover,
header.top-header.customer-header
  nav.navbar.navbar-expand-lg
  ul.navbar-nav
  li.nav-item.active
  .nav-link,
li.nav-item.dropdown.dropdown-large:hover .nav-link,
a.nav-link.dropdown-toggle.dropdown-toggle-nocaret.show {
  border-bottom: 0px solid #9ea1a7;
}

header.top-header.customer-header
  nav.navbar.navbar-expand-lg
  ul.navbar-nav
  li.nav-item.active
  .nav-link {
  border-bottom: 0px solid #9ea1a7;
}
.btn i {
  margin-left: 0px;
}
.card-header {
  border-bottom: 1px solid #06a180;
}

.custom-button2 {
  border: none;
  color:#06a180;
}
.custom-button2:hover {
  background-color:#06a180;
  color: #fff;
  transition: 0.7s;
  border-radius: 3px;
}

.quick input {
  font-size: 22px;
  padding: 8px 0px;
  width: 24%;
  background: #e3e3e3;
  color: #4a5568;
  border: 0px solid #f7f8fa;
  margin: 0px;
  transition: background 0.2s ease, color 0.2s ease, box-shadow 0.2s ease,
    transform 0.2s ease;
  transform: translateY(0);
}

.quick .want-wht input {
  width: 14%;
  margin-bottom: 5px;
  font-size: 15px;
  border-radius: 5px;
  margin-left: 5px;
}

.reports-all-main .nav {
  display: flex;
  flex-wrap: initial;
  flex-direction: column;
  border-bottom: none !important;
}

.reports-all-main .nav.nav-tabs .nav-link {
  border-radius: 0;
  margin: 0;
  text-align: center;
  font-size: 16px;
  border: none;
  background: #f3f8fb;
  margin-top: 2px;
  color: black;
}

.reports-all-main .nav.nav-tabs .nav-link i {
  font-size: 24px;
}
.report-title {
  border-radius: 3px 3px 0 0;
  border-bottom: 1px solid #d5d9de;
  position: relative;
  height: 34px;
  display: -webkit-flex;
  display: flex;
}

.report-overview {
  border: 1px solid #d5d9de;
  margin-bottom: 8px;
  border-radius: 3px;
}

.report-overview p {
  padding: 6px 12px;
  margin: 0px;
  color: #696c74;
  font-size: 11px;
  vertical-align: middle;
}

.add-fav {
  padding: 6px 10px;
  background-color: #ffffff;
  border-right: 1px solid #d5d9de;
  cursor: pointer;
  border-radius: 3px 0 0 0;
  margin: 0px;
}

.add-fav i.bi.bi-star-fill {
  color: #ffd801;
}

.add-fav:hover {
  background-color: #eff1f3;
}
.exp-type {
  padding: 20px;
  border: 1px solid #e3ebef;
  background: #f3f8fb;
}
.exp-type .custom-raio ul {
  width: 100%;
  display: block;
  margin-top: 20px;
}
.exp-type .custom-raio ul li {
  display: inline-block;
  margin-right: 10px;
  width: 31%;
  margin-top: 10px;
  vertical-align: top;
}
.report-title a {
  display: block;
  width: 100%;
  text-decoration: none;
  background-color: #ededed;
  border-radius: 3px 3px 0 0;
  font-size: 13px;
  line-height: 17px;
  font-weight: bold;
  padding: 8px 12px;
  color: #495057d1;
}
.report-title a:hover {
  background: #e1e1e1;
  color:#06a180;
}
.report-title a span {
  display: inline-block;
  color: #adadad;
  font-size: 11px;
  margin-bottom: 0px;
  line-height: 17px;
  margin-right: 5px;
  vertical-align: middle;
}
.report-title a:hover span {
  color:#06a180;
}
.reports-all-main .nav-tabs .nav-link.active {
  color: #fff;
  background:#06a180;
  border-left: 5px solid #000;
}
.reports-all-main .nav-tabs .nav-link:focus,
.reports-all-main .nav-tabs .nav-link:hover {
  color: #fff;
  background:#06a180;
  border-left: 5px solid #000;
}
.fade:not(.show) {
  opacity: 0;
  display: none;
}
.report-filters {
  margin-top: 10px;
  margin-bottom: 10px;
}

.report-filters span {
  font-size: 11px;
  letter-spacing: 1px;
  color: #999;
}
.report-filters .nav-danger.nav-tabs .nav-link.active {
  color: #ffffff;
  border-color:#06a180#06a180;
  background:#06a180;
}

.report-filters .nav-danger.nav-tabs .nav-link {
  padding: 5px 12px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 0;
  color:#06a180;
}

.see-full {
  font-size: 13px;
}

.see-full a:hover {
  color: black;
  text-decoration: none;
}

.want-more {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.graph-pic {
  margin-right: 16px;
}
.graph-content h3 {
  color: #232329;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 4px;
}
.graph-content p {
  color: #232329;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 10px;
}

/* reports tab end here  */

/* main retail css here  */

.more-opthead {
  cursor: pointer;
  display: inline-block;
  margin: 0;
}

.more-opthead img {
  width: 8px;
  transition: all 0.3s;
}

.more-opthead.opened img {
  transform: rotate(90deg);
  transition: all 0.3s;
}

.more-opt {
  display: none;
}

.clients-list-box-result {
  height: 74vh;
  overflow: auto;
}

.clients-list-box-result ul {
  padding: 0;
  margin: 0;
}

.clients-list-box-result ul li {
  list-style: none;
}

.open-tic {
  padding-top: 8px;
  padding-right: 10px;
  font-size: 18px;
  font-weight: 700;
}

.clients-list-box-result ul li a {
  background-color: #f8f9fa;
  padding: 10px 8px;
  transition: all 0.25s ease;
  border-bottom: 1px solid #eff1f3;
  outline: 0;
  box-shadow: 0 1px 4px #c8c8c8;
  margin-bottom: 0px !important;
  display: block;
  color: #4c5258;
  font-size: 18px;
}

.clients-list-box-result ul li a:hover {
  background:#06a18014;
  border-bottom: 1px solid#06a180;
}

.clients-list-box-result ul li a span {
  display: block;
  font-size: 16px;
}

.clint-name {
  font-size: 34px;
  color:#06a180;
  margin: 0;
}

.prsn-dtl a {
  color: black;
  font-size: 20px;
  margin-right: 5px;
}

.all-detals label {
  font-size: 14px;
}
.all-detals {
  font-size: 18px;
}

.item-add-her {
  border: 1px solid black;
}

.item-selected-area {
  height: 50vh;
  overflow: auto;
}

.ticket-proccessing {
  border-bottom: 1px solid black;
  padding: 10px;
}

.butons-ticket {
  text-align: right;
}
.butons-ticket strong {
  font-size: 20px;
  vertical-align: middle;
  margin-right: 5px;
}
.no-select {
  font-size: 22px;
  color:#06a180;
  text-align: center;
  margin-top: 10px;
}
.foot-table {
  color: white;
  background-color: #3e3934;
  font-weight: 500;
  font-size: 16px;
}
.foot-table span {
  font-size: 26px;
  font-weight: 900;
  display: block;
  text-align: center;
}

.item-tabs .report-filters ul {
  background-color:#06a180;
}

.item-tabs .report-filters ul li {
  width: 20%;
}
.item-tabs .report-filters .nav-tabs .nav-link {
  color: white;
  text-align: center;
}
.item-tabs .report-filters .nav-tabs .nav-link .tab-title {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.item-tabs .report-filters .nav-tabs .nav-link:hover {
  background-color: #3e3934;
  border-color: #3e3934;
}

.item-tabs .report-filters .nav-danger.nav-tabs .nav-link.active {
  color: #ffffff;
  background-color: #3e3934;
  border-color: #3e3934;
}

.inner-prd {
  margin-top: 20px;
}

.auto-addedtic {
  margin-top: 40px;
}

.cat-type {
  overflow: visible !important;
}

.custom-raio ul li input[type="checkbox"] {
  position: absolute;
  visibility: hidden;
}
.custom-raio input[type="checkbox"]:checked ~ .check::before {
  background:#06a180;
}
.custom-raio input[type="checkbox"]:checked ~ .check {
  border: 3px solid#06a180;
  background-color:#06a180;
}
.custom-raio input[type="checkbox"]:checked ~ label {
  color:#06a180;
  font-weight: 700;
}
.custom-raio input[type="checkbox"] ~ .check {
  border-radius: 0;
}
.selected_staffpkg ul li span {
  display: block;
  color: #000;
  font-size: 13px;
}
.custom-raio input[type="checkbox"]:checked ~ .check::before {
  background: transparent;
  content: "\2713";
  color: white;
  font-weight: 900;
  z-index: 1111;
  top: -5px;
  left: 1px;
  font-size: 13px;
}

.inner-prd label.form-label {
  font-size: 18px;
  font-weight: 500;
}

.payment-md-select label.form-label {
  font-size: 18px;
  font-weight: 700;
}

.payment-md-select button.active {
  color: #fff;
  background-color:#06a180;
  border-color:#06a180;
  box-shadow: none;
}

.remain-amount {
  padding: 15px;
  background: #ffffff;
  margin-top: 14px;
  font-size: 36px;
  color: #3e3934;
  border: 1px solid #3e39347a;
  line-height: 1.2;
}

.remain-amount strong {
  font-size: 22px;
  display: block;
}

.selected-payment {
  padding: 15px;
  display: none;
  background: #f7f8fa;
  margin-top: 14px;
  font-size: 24px;
  color: #3e3934;
  border: 1px solid #3e39347a;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 15px;
}

.selected-payment span {
  margin-left: 5px;
}

.selected-payment a {
  color:#06a180;
}

.selected-payment input {
  width: 100px;
  height: 40px;
  padding: 0px 10px;
  font-size: 20px;
}

.comple-sale {
  display: none;
}
.comple-sale label.form-label {
  font-size: 22px;
  font-weight: 700;
}

.result-item-add,
.pay-now,
.prd-result-mach,
.reslt-data {
  display: none;
}

.prd-result-mach th:first-child {
  font-size: 26px;
}
.prd-result-mach td:first-child {
  font-size: 20px;
}

#product-dtl-in,
#service-dtl-in,
div#auto-dtl-in,
#payments-dtl-in {
  display: none;
}

.product-dtl-in h5 {
  font-size: 20px;
  margin: 0;
}

.prd-totl {
  margin-top: 15px;
  padding: 10px;
  border: 1px solid #3e3934;
  color: #3e3934;
  font-weight: 700;
  font-size: 22px;
}

.product-dtl-in span {
  display: block;
  text-align: right;
  font-size: 20px;
  font-weight: 900;
}

.product-dtl-in .input-group label.form-label {
  margin-top: 7px;
}

.product-dtl-in .input-group {
  margin-bottom: 10px;
}

.product-dtl-in .input-group input {
  border: 0;
  font-size: 19px;
  font-weight: 700;
  margin-left: 10px;
}

.product-dtl-in .input-group input:focus,
.product-dtl-in .discount-count .input-group input:focus {
  border: 1px solid;
  border-color:#06a180;
}
.product-dtl-in .discount-count .input-group input {
  border: 1px solid #ced4da;
  font-weight: 400;
  font-size: 18px;
}

.product-dtl-in .discount-count span,
.product-dtl-in strong {
  margin-right: 10px;
  margin-top: 7px;
  float: none;
  font-size: 19px;
  margin-left: 10px !important;
}

.product-dtl-in .input-group .form-check {
  padding-left: 0;
  margin-top: 10px;
}

.product-dtl-in .input-group .form-check input {
  margin: 0;
  margin-right: 5px;
  border: 1px solid rgba(0, 0, 0, 0.25);
}

/* main retail css end here  */

.multi-button:hover input {
  color: #a0aec0;
}

.multi-button.active input {
  color: #a0aec0;
}

.srch-rzl {
  display: none;
}

.multi-button:hover input:hover,
.multi-button.active input.active {
  background:#06a180;
  color: #fff;
  box-shadow: 0 0 0.3em 0 rgb(6 161 128) !important ;
  transform: translateY(-0.2em);
}

.rsl-div {
  display: none;
}

/* .quick input:hover{
    background:#06a180 !important;
    color: white;
} */

.custom-raio ul {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.custom-raio ul li {
  color: black;
  position: relative;
  margin: 0;
  padding: 0 0 0 0;
}

.custom-raio ul li input[type="radio"] {
  position: absolute;
  visibility: hidden;
}

.custom-raio ul li label {
  display: block;
  position: relative;
  font-weight: 300;
  font-size: 16px;
  padding: 0px 0px 0px 25px;
  margin: 0px auto;
  height: auto;
  z-index: 9;
  cursor: pointer;
  /* -webkit-transition: all 0.25s linear; */
}

.custom-raio ul li .check {
  display: block;
  position: absolute;
  border: 3px solid #aaaaaa;
  border-radius: 100%;
  height: 16px;
  width: 16px;
  top: 3px;
  left: 3px;
  z-index: 5;
  transition: border 0.25s linear;
  -webkit-transition: border 0.25s linear;
}

.custom-raio ul li .check::before {
  display: block;
  position: absolute;
  content: "";
  border-radius: 100%;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  margin: auto;
  transition: background 0.25s linear;
  -webkit-transition: background 0.25s linear;
}

.custom-raio input[type="radio"]:checked ~ .check {
  border: 2px solid #ffffff;
  outline: 2px solid#06a180;
}
.inner-table span.control-inner {
  display: block;
  width: 215px;
  white-space: break-spaces;
  padding-left: 5px;
}
.inner-table span.control-inner.time {
  width: 130px;
}
.custom-raio input[type="radio"]:checked ~ label {
  color:#06a180;
  font-weight: 700;
}

.custom-raio ul li:hover label {
  color:#06a180;
}

.custom-raio ul li:hover .check {
  border: 3px solid#06a180;
}

.custom-raio input[type="radio"]:checked ~ .check::before {
  background:#06a180;
}

.top-header .navbar .dropdown-menu .apps .apps-name {
  color: #34383f;
  font-size: 15px;
}

.top-header .navbar .dropdown-menu .msg-time {
  font-size: 12px;
}

.top-header .navbar .dropdown-menu .dropdown-msg-user {
  font-size: 14px;
}

.top-header .navbar .dropdown-menu .dropdown-msg-text {
  font-size: 11px;
}

.header-message-list {
  position: relative;
  height: 400px;
}

.header-notifications-list {
  position: relative;
  height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}

/* width */
.header-notifications-list::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.header-notifications-list::-webkit-scrollbar-track {
  background: #000;
}

/* Handle */
.header-notifications-list::-webkit-scrollbar-thumb {
  background:#06a180;
}

/* Handle on hover */
.header-notifications-list::-webkit-scrollbar-thumb:hover {
  background:#06a180;
}

.top-header .navbar .dropdown-menu .notification-box {
  width: 48px;
  height: 48px;
  font-size: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e1e6eb;
}

.top-header .navbar .nav-link .notify-badge {
  position: absolute;
  top: -8px;
  right: -8px;
  color: #fff;
  font-size: 13px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #06a180;
}

/* Sidebar */

@media only screen and (max-width: 426px) {
  .addclient-button {
    width: 100%;
  }
}

.toggle-icon {
  font-size: 26px;
  cursor: pointer;
  color:#06a180;
}

.srch-cl {
  margin-top: 29px;
}

h3.saved-search-cl {
  font-size: 21px;
  display: inline-block;
  cursor: pointer;
}

h3.saved-search-cl img {
  width: 8px;
  transition: all 0.3s;
}

h3.filter-search-cl {
  font-size: 21px;
  display: inline-block;
  cursor: pointer;
}

h3.filter-search-cl img {
  width: 8px;
  transition: all 0.3s;
}

h3.filter-search-cl.rotate-arrow img,
h3.saved-search-cl.rotate-arrow img {
  transform: rotate(90deg);
  transition: all 0.3s;
}

.saved-search {
  display: none;
}

.filter-search
  .select2-container--bootstrap4.select2-container--focus
  .select2-selection {
  border-color: #e72e2e;
  -webkit-box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
  box-shadow: none;
  cursor: pointer;
}

.filter-search {
  display: none;
}

.filter-search .form-check {
  display: inline-block;
  margin-top: 15px;
  margin-right: 15px;
}

.curent-dated label.form-label {
  font-size: 24px;
  font-weight: 900;
  margin-right: 12px;
  margin-top: 2px;
}

/* add schedule steps css start here  */
.add-in-sch {
  border: 1px solid #c7cacf;
  padding: 15px;
  margin-top: 14px;
}

.custom-box,
.not-avaliable,
.ad-srch,
.yes_favorite,
.mtch-prd,
.div-sbcat,
.yes_discount,
.yes_restrict,
.custom-box22,
.yes_promotional,
.yes_commission {
  display: none;
}

.ren-sercat {
  color:#06a180;
  margin-left: 10px;
}

.btn-add-sercat {
  padding: 17px;
  display: block;
  background-color: #f7f8fa;
  width: 100%;
  text-align: left;
}

.rename-sercat {
  display: none;
}

a.remove-another-time {
  /* margin: 0 auto; */
  margin-top: 34px;
  display: inline-block;
  font-size: 18px;
}

a.remove-another-time:hover {
  color: #000;
}

.classes-table {
  font-size: 15px;
}

.service-opt .form-check.form-switch {
  display: inline-flex;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 22px;
  vertical-align: sub;
}

.add-sbcat {
  margin-top: 36px;
  display: inline-block;
  color:#06a180;
  font-size: 14px;
  cursor: pointer;
}

.sidebar-wrapper ul {
  padding: 0;
  margin: 0;
  list-style: none;
  background: 0 0;
}

.sidebar-wrapper .metismenu {
  background: 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 10px;
  padding-top: 0;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.card-header.py-3 h5 {
  text-transform: capitalize;
}
.note-here {
  color: #06a180 ;
  margin-left: 5px;
  font-size: 14px;
  vertical-align: middle;
  cursor: pointer;
  margin-top: -3px;
  display: inline-block;
}
.catagry-head {
  background-color: #f3f8fb;
  padding: 10px;
}
thead.bg-success {
  background-color: #00a352 !important;
}

.date-heading {
  background: black;
  color: white;
  padding: 15px 35px;
  border-radius: 5px;
  display: inline-block;
  margin-bottom: 20px;
}

li.page-item.green-ver.active .page-link.green-ver {
  background-color: #00a352 !important;
  border-color: #00a352 !important;
}
.page-link.green-ver {
  color: #00a352;
}

.catagry-head .text-capitalize {
  color: #555555;
  font-size: 16px;
  font-weight: 700;
}
.menu-label {
  padding: 20px 15px 5px 5px;
  color: #b0afaf;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.5px;
}

.metismenu .has-arrow:after {
  position: absolute;
  content: "";
  width: 0.5em;
  height: 0.5em;
  border-style: solid;
  border-width: 1.2px 0 0 1.2px;
  border-color: initial;
  right: 15px;
  transform: rotate(-45deg) translateY(-50%);
  transform-origin: top;
  top: 50%;
}

/* Page Content */

.page-content {
  margin-left: 260px;
  margin-top: 60px;
  padding: 1.5rem;
  transition: all 0.2s;
}

/* Utilities */
.radius-10 {
  border-radius: 10px;
}
.radius-15 {
  border-radius: 15px;
}
.radius-30 {
  border-radius: 30px;
}
.font-13 {
  font-size: 13px;
}
.font-14 {
  font-size: 14px;
}
.font-16 {
  font-size: 16px;
}

.font-20 {
  font-size: 18px;
  font-weight: 700;
}
.font-22 {
  font-size: 22px;
  font-weight: 700;
}
.font-24 {
  font-size: 24px;
}
.font-30 {
  font-size: 30px;
}

.font-35 {
  font-size: 35px;
}

h5 {
  font-size: 18px;
  font-weight: 700;
}
.form-select {
  border-radius: 2px;
  border: 1px solid #e3e1e1;
  font-size: 14px;
}

.text-justify {
  text-align: justify;
}

.table-responsive {
  white-space: nowrap;
}

.fm-menu .list-group a {
  font-size: 16px;
  color: #5f5f5f;
  display: flex;
  align-items: center;
}

.fm-menu .list-group a i {
  font-size: 23px;
}

.fm-menu .list-group a:hover {
  background-color: rgb(52 97 255 / 15%);
  color:#06a180;
  transition: all 0.2s ease-out;
}

.add-post {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.15);
}

.fm-icon-box {
  font-size: 32px;
  background: #fff;
  width: 52px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.alert.border-0.bg-light-danger.alert-dismissible.fade.show.py-2 {
  padding-top: 9.5px !important;
  padding-bottom: 9.5px !important;
}

.card.search-certificate-result .input-group .form-select {
  border-radius: 0.25rem !important;
}

.back-to-top {
  display: none;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 22px;
  color: #fff;
  position: fixed;
  border-radius: 50%;
  bottom: 20px;
  right: 12px;
  background-color:#06a180;
  z-index: 5;
  align-items: center;
  justify-content: center;
}

.img-link {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
}

input.form-control.glow {
  animation: mymove 1s;
  background-color: white;
}

@keyframes mymove {
  0% {
    background-color: white;
  }
  70% {
    background-color: #ffff00;
  }
  100% {
    background-color: white;
  }
}

.back-to-top:hover {
  color: #fff;
  background-color: #000;
  transition: all 0.5s;
}

.back-to-top.showbtn {
  display: inline-flex;
}

.project-user-groups img {
  margin-right: -14px;
  border: 1px solid #e4e4e4;
  padding: 2px;
  cursor: pointer;
}

.project-user-plus {
  width: 33px;
  height: 33px;
  margin-right: 0px;
  line-height: 33px;
  background: #fff;
  border-radius: 50%;
  text-align: center;
  font-size: 22px;
  cursor: pointer;
  border: 1px dotted #a9b2bb;
  color: #404142;
}

.user-groups img {
  margin-left: -14px;
  border: 1px solid #e4e4e4;
  padding: 2px;
  cursor: pointer;
}

.user-plus {
  width: 33px;
  height: 33px;
  margin-left: -14px;
  line-height: 33px;
  background: #fff;
  border-radius: 50%;
  text-align: center;
  font-size: 22px;
  cursor: pointer;
  border: 1px dotted #a9b2bb;
  color: #404142;
}

.icon-box {
  width: 60px;
  height: 60px;
  background-color: #ffffff;
  border-radius: 50%;
  border: 1px solid #c7c8cb;
  font-size: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.form-check .form-check-input {
  border: 1px solid #06a180 ;
}
.form-check-input:checked {
  background-color: #06a180 ;
  border-color: #06a180 ;
}
.product-box {
  width: 52px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
  background-color: #ffffff;
}

.best-product-item {
  background-color: #ffffff;
  cursor: pointer;
  border-radius: 10px;
  padding: 0.5rem;
}

.best-product-item:hover {
  background-color: #f5f5f5;
}

.product-box img {
  width: 100%;
}

.best-product {
  position: relative;
  height: 420px;
}

.by-device-container {
  position: relative;
  height: 120px;
}

.chart-container1 {
  position: relative;
  height: 340px;
}

.sepration {
  height: 100px;
}

.sepration-2 {
  height: 50px;
}

.gmaps,
.gmaps-panaroma {
  height: 400px;
  background: #eee;
  border-radius: 3px;
}

.theme-icons {
  background-color: #fff;
  color: #434547;
}

.cursor-pointer {
  cursor: pointer;
}

.icon-badge {
  width: 45px;
  height: 45px;
  font-size: 1.2rem;
  background: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.product-img-2 {
  width: 45px;
  height: 45px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid #e6e6e6;
}

.error-social a {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  margin: 5px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.productlist .product-box {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
  border: 1px solid #e2e3e4;
  background-color: #ffffff;
}

.productlist .product-box img {
  width: 100%;
}

.productlist .product-title {
  color: rgb(25, 27, 34);
}

.orderlist .product-box {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
}

.orderlist .product-box img {
  width: 100%;
  border-radius: 5px;
  height: 100%;
  object-fit: cover;
}

.orderlist .product-title {
  color: rgb(25, 27, 34);
  font-weight: 700;
  /* min-width: 151px; */
  overflow: hidden;
  display: -webkit-box;
  width: 57%;
  height: 20px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
}

.breadcrumb-title {
  font-size: 20px;
  border-right: 1.5px solid #aaa4a4;
}

.page-breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  font-family: LineIcons;
  content: "\ea5c";
}

.page-breadcrumb .breadcrumb li.breadcrumb-item {
  font-size: 16px;
}

.iconmenu .ps__rail-x {
  height: 0px;
}

.iconmenu .ps__rail-y {
  width: 0px;
}

.iconmenu .ps__rail-x.ps--clicking .ps__thumb-x,
.iconmenu .ps__rail-x:focus > .ps__thumb-x,
.iconmenu .ps__rail-x:hover > .ps__thumb-x {
  height: 5px;
}

.iconmenu .ps__rail-y.ps--clicking .ps__thumb-y,
.iconmenu .ps__rail-y:focus > .ps__thumb-y,
.iconmenu .ps__rail-y:hover > .ps__thumb-y {
  width: 5px;
}

.w_chart {
  position: relative;
  display: inline-block;
  width: 80px !important;
  height: 80px !important;
  text-align: center;
  font-size: 1.4em;
}

.w_percent {
  display: inline-block;
  line-height: 80px !important;
  z-index: 2;
  color: #0a0808;
}

.w_percent:after {
  content: "%";
  margin-left: 0.1em;
}

.w_chart canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 80px !important;
  height: 80px !important;
}

#geographic-map {
  width: 100%;
  height: 400px;
}

.new-customer-list {
  position: relative;
  height: 500px;
}

.new-customer-list .customer-list-item {
  padding: 0.5rem;
  border-radius: 10px;
}

.new-customer-list .customer-list-item:hover {
  background-color: #f0f0f0;
}

.top-sellers-list {
  position: relative;
  height: 460px;
}

.top-sellers-list .sellers-list-item {
  padding: 0.5rem;
  border-radius: 10px;
}

.top-sellers-list .sellers-list-item:hover {
  background-color: #f0f0f0;
}

.client-message {
  position: relative;
  height: 565px;
}

.client-message .client-messages-list {
  padding: 0.5rem;
}

.client-message .client-messages-list:hover {
  background-color: #f0f0f0;
}

/* Widgets */

.widget-icon {
  width: 48px;
  height: 48px;
  background-color: #fafafa;
  border-radius: 50%;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
}

.widget-icon-large {
  width: 54px;
  height: 54px;
  background-color: #fafafa;
  border-radius: 10px;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

/* swicther */

.btn-switcher {
  position: fixed;
  right: 0px;
  top: 40%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.header-colors-indigators .indigator {
  width: 50px;
  height: 50px;
  background-color: #f7f8fa;
  border-radius: 10px;
  cursor: pointer;
}

.expenses-num {
  font-weight: 500;
  color: black;
}

.expenses-num span {
  display: inline-block;
  padding: 5px 12px;
  background:#06a18014;
  color:#06a180;
  font-weight: 600;
}

.clint-pic-upload .avatar-upload {
  position: relative;
  max-width: 205px;
  margin: 0px auto;
}
.clint-pic-upload .avatar-upload .avatar-edit {
  position: absolute;
  right: 12px;
  z-index: 1;
  top: 10px;
}
.clint-pic-upload .avatar-upload .avatar-edit input {
  display: none;
}

.clint-pic-upload .avatar-upload .avatar-edit input + button.btn.btn-primary {
  /* display: inline-block; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  border-radius: 100% !important;
  background: #ffffff;
  border: 1px solid #d2d2d2;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  /* transition: all 0.2s ease-in-out; */
}
span.memb_type_2 {
  color: #06a180 ;
  padding: 3px 13px;
  border-radius: 5px;
  width: 214px;
  display: block;
  text-transform: capitalize;
  text-align: center;
  margin: 0 auto;
  background: #fbeaed;
}
span.memb_type {
  color: black;
  padding: 3px 13px;
  border-radius: 5px;
  width: 214px;
  display: block;
  text-transform: capitalize;
  text-align: center;
  margin: 0 auto;
  background: #e5e5e5;
}
span.memb_type.super {
  background: #009688;
}
span.memb_type.elite {
  background: #bb6600;
}

span.memb_type.platinum {
  background: #673ab7;
}
span.memb_type.others {
  background: #e3e3e3;
}
.clint-pic-upload
  .avatar-upload
  .avatar-edit
  input
  + button.btn.btn-primary:hover {
  background:#06a180;
  border-color:#06a180;
}

.clint-pic-upload .avatar-upload .avatar-edit input + button.btn.btn-primary I {
  color:#06a180;
}

.clint-pic-upload
  .avatar-upload
  .avatar-edit
  input
  + button.btn.btn-primary:hover
  i {
  color: #ffffff;
}

.clint-pic-upload .avatar-upload .avatar-preview {
  width: 192px;
  height: 192px;
  position: relative;
  border-radius: 100%;
  border: 4px solid #d2d2d2;
  box-shadow: 0 0.25rem 0.75rem rgb(18 38 63 / 8%);
}

.clint-pic-upload .avatar-upload .avatar-preview > div {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.file-download {
  width: 100px;
}

/* Responsive */
@media screen and (max-width: 1024px) {
  .sidebar-wrapper {
    left: -300px;
  }

  .page-content {
    margin-left: 0px;
  }

  .top-header .navbar {
    left: 0px;
  }

  /* Toggeled */

  .wrapper.toggled .sidebar-wrapper {
    left: 0px;
  }

  .wrapper.toggled .page-content {
    margin-left: 0px;
  }

  .wrapper.toggled .top-header .navbar {
    left: 0px;
  }

  .wrapper.toggled .overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #000;
    opacity: 0.6;
    z-index: 12;
    display: block;
    cursor: move;
    transition: all 0.2s ease-out;
  }

  .top-header .navbar .searchbar {
    position: relative;
    width: 30%;
    display: none;
  }
}

/*custom css by designer */

:focus {
  box-shadow: none !important;
}

/* customer-index pagee css custom start from here  */

.customer-header.top-header .navbar {
  left: 0;
  height: 75px;
}

.page-content.customer-contnent {
  margin-left: 0;
  margin-top: 75px;
  padding: 1.5rem;
  transition: all 0.2s;
}
span.num-ox {
  font-size: 30px;
  color:#06a180;
  display: block;
  margin-top: 5px;
}

.manage-task,
.manage-task:hover {
  color: #4c5258;
}

.top-head-nav a.nav-link.btn.btn-dark {
  background-color:#06a18000;
  border-color: #000000;
  color: #000000;
  /* padding: 0.5rem 1rem; */
  padding-right: 1rem !important;
  padding-left: 1rem !important;
  font-size: 14px;
}
.top-head-nav a.nav-link.btn.btn-dark i {
  margin-left: 0;
}

.top-head-nav .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.111em;
}

.top-head-nav .dropdown-menu[data-bs-popper] {
  right: 0;
  left: auto;
}

.top-head-nav a.nav-link.btn.btn-dark:hover,
.top-head-nav a.nav-link.btn.btn-dark:focus,
.top-head-nav li.nav-item.active a.nav-link.btn.btn-dark {
  background-color: #000000;
  color: #fff;
}

.tabs.chooce-one {
  margin-top: 30px;
}
/* .chooce-one .tabs-nav {
  margin: 0 auto;
  padding: 0;
  max-width: 400px;
  margin-bottom: 15px;
} */
.chooce-one .tabs-nav {
  text-align: center;
  display: block;
  padding: 0;
}
.chooce-one .tabs-nav li {
  list-style: none;
  align-items: center;
  margin: 0 auto;
  display: inline-block;
}
.swal2-styled.swal2-confirm {
  background-color:#06a180 !important;
}
.chooce-one .tabs-nav li button {
  height: 50px;
  width: 200px;
  display: flex;
  color:#06a180;
  background: #fff;
  border: 1px solid #d2d2d2;
  border-radius: 5px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  margin-right: 5px;
  box-shadow: 0 0.25rem 0.75rem rgb(18 38 63 / 8%);
}

a.renew-pkg {
  color:#06a180;
  background: #06a18014;
  padding: 3px 11px 3px 11px;
  border-radius: 5px;
  font-weight: 500;
}
table td a:hover {
  color: #000000;
}

a.renew-pkg:hover {
  color: white;
  background:#06a180;
}

.chooce-one .tabs-nav li button:hover {
  color: #ffffff;
  background-color: #000000;
  border-color: #000000;
}

.chooce-one .tab-active button {
  background:#06a180 !important;
  color: #fff !important;
  border-color:#06a180 !important;
  cursor: pointer;
}

.chooce-one .tabs-stage {
  clear: both;
}

.page-link:hover,
.page-link:focus {
  z-index: 2;
  color: #ffffff;
  background-color: #000000;
  border-color: #000000;
}

.custom-logo img {
  width: 220px;
}

.user-changepass {
  height: 58px;
  padding: 5px;
  background: #3e3934;
  color: white;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 205px;
  /* width: 65%; */
  margin-left: auto;
}
.custom-button-image {
  background: transparent;
  border: none;
}
.header-username {
  font-size: 14px;
  font-weight: 600;
  width: 100%;
  text-align: center;
}

a.change-pass-btn,
a.logout-btn {
  color: white;
  font-size: 13px;
}

.change-pass-btn {
  margin-right: 5px;
}
.text-info-bold {
  color: #32bfff !important;
  font-weight: 700;
}

.btn-primary {
  color: #fff;
  background-color: #303030;
  border-color: #303030;
  box-shadow: none;
  /* transition: background-color 1s ease-out 50ms; */
  border-radius: 2px !important;
  font-size: 14px;
  padding: 6px 6px 6px 6px;
}

.btn-primary i {
  margin-left: 0px !important;
}

.btn-primary:hover,
.btn-primary:focus {
  color: #fff;
  background-color:#06a180;
  border-color:#06a180;
  box-shadow: none;
}
.btn-libility-primary:hover,
.btn-libility-primary:focus {
  color: #fff;
  background-color:#06a180;
  border-color:#06a180;
  box-shadow: none;
}

.btn-check:active + .btn-primary:focus,
.btn-check:checked + .btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.form-control:focus,
.form-select:focus {
  color: #3e3934;
  background-color: #fff;
  border-color:#06a180 !important;
  outline: 0;
}

.link-hirarcy {
  padding-top: 10px;
  font-size: 18px;
  font-weight: bold;
  color: #666666;
}

.link-hirarcy a,
.link-hirarcy a:hover {
  color:#06a180;
}

.grand-totl-fine {
  width: 30%;
  margin-left: auto;
  margin-top: 20px;
}
@media only screen and (max-width: 769px) {
  .grand-totl-fine {
    width: 96%;
    margin-left: auto;
    margin-top: 20px;
    display: flex;
  }
}
@media only screen and (max-width: 1025px) {
  .grand-totl-fine {
    width: 96%;
    margin-left: auto;
    margin-top: 20px;
    display: flex;
  }
}
@media only screen and (max-width: 376px) {
  .grand-totl-fine {
    width: 70%;
    margin-left: auto;
    margin-top: 20px;
    display: flex;
  }
}
@media only screen and (max-width: 426px) {
  .grand-totl-fine {
    width: 70%;
    margin-left: auto;
    margin-top: 20px;
    display: flex;
  }
}
.grand-totl-fine h5 {
  background:#06a180;
  color: white;
  padding: 10px;
  font-size: 16px;
  margin: 0;
}

.grand-totl-fine span {
  border: 1px solid#06a180;
  display: block;
  font-size: 22px;
  padding: 7px 10px;
  font-weight: 600;
  border-top: 0;
}

.trainee-add label {
  font-weight: 700;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.fitness-goals {
  /* font-size: 15px;
  margin-top: -8px; */
  background: #e7f7ea;
  border-radius: 5px;
  font-size: 13px;
  color: #00a907;
}

.inside-table {
  color: #00a907;
  font-size: 25px;
  /* vertical-align: sub; */
  margin-top: -10px;
  margin-bottom: -5px;
  display: block;
}

.grand-total-cart {
  width: 30%;
  display: block;
  margin-left: auto;
  padding: 25px;
  background: #f3f8fb;
  font-size: 16px;
  font-weight: 500;
  padding-top: 15px;
  margin-top: 10px;
}
.grand-total-cart-1 {
  padding: 25px;
  background: #f3f8fb;
  font-size: 16px;
  font-weight: 500;
  padding-top: 15px;
  margin-top: 10px;
}

@media only screen and (max-width: 1025px) {
  .grand-total-cart {
    width: 50%;
  }
}
@media only screen and (max-width: 769px) {
  .grand-total-cart {
    width: 40%;
  }
}
@media only screen and (max-width: 426px) {
  .grand-total-cart {
    width: 70%;
  }
}
@media only screen and (max-width: 322px) {
  .grand-total-cart {
    width: 99%;
  }
}
.cart-entry {
  margin-top: 10px;
}
.cart-entry span {
  font-weight: 700;
  float: right;
  font-size: 18px;
}

.remove-item {
  color: #06a180 ;
  font-size: 25px;
  margin-top: -19px;
  margin-bottom: -8px;
}
.cancel-order {
  background-color:#06a180;
  border-color:#06a180;
}

.cancel-order:hover {
  background-color: #303030;
  border-color: #303030;
}

ul.client-searched-record {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  left: 0;
  right: 0;
  border: 1px solid #cad1d5;
  z-index: 444;
  background: #f7f7f7;
  top: 35px;
  max-height: 385px;
  overflow: auto;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 1in 1in -0.25in;
  border-radius: 5px;
}
ul.client-searched-record-1 {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  left: 0;
  right: 0;
  border: 1px solid #cad1d5;
  z-index: 444;
  background: #f7f7f7;
  top: 262px;
  max-height: 228px;
  overflow: auto;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 1in 1in -0.25in;
  border-radius: 5px;
}
/* width */
ul.client-searched-record::-webkit-scrollbar {
  width: 5px;
}
ul.client-searched-record-1::-webkit-scrollbar {
  width: 5px;
}

/* Track */
ul.client-searched-record::-webkit-scrollbar-track {
  background: #000;
}
ul.client-searched-record-1::-webkit-scrollbar-track {
  background: #000;
}

/* Handle */
ul.client-searched-record::-webkit-scrollbar-thumb {
  background: #06a180 ;
}
ul.client-searched-record-1::-webkit-scrollbar-thumb {
  background: #06a180 ;
}

/* Handle on hover */
ul.client-searched-record::-webkit-scrollbar-thumb:hover {
  background: #fff;
}
ul.client-searched-record-1::-webkit-scrollbar-thumb:hover {
  background: #fff;
}
ul.client-searched-record li a span {
  float: right;
  padding-right: 10px;
}
ul.client-searched-record-1 li a span {
  float: right;
  padding-right: 10px;
}
ul.client-searched-record li a {
  padding: 8px;
  display: block;
  border-top: 1px solid #cad1d5;
  color: #000;
  text-transform: capitalize;
}
ul.client-searched-record-1 li a {
  padding: 8px;
  display: block;
  border-top: 1px solid #cad1d5;
  color: #000;
  text-transform: capitalize;
}
ul.client-searched-record li a:hover {
  background-color: #06a180 ;
  color: white !important;
}
ul.client-searched-record-1 li a:hover {
  background-color: #06a180 ;
  color: white !important;
}
button#menu-open-close {
  margin: 0 !important;
  border: 0 !important;
  background-color:#06a180 !important;
  padding-right: 10px !important;
}
.recnt-history {
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  background: #eff4f7;
}
.no-record-li {
  color: #ffffff;
  text-transform: capitalize;
  font-size: 17px;
  background:#06a180;
  padding: 8px;
}
.no-record-li-1 {
  color: #000000;
  text-transform: capitalize;
  font-size: 15px;
  background: #ffffff;
  padding: 8px;
  text-align: center;
}

.fitness-goals i {
  color: #00a907;
  font-size: 25px;
  vertical-align: sub;
  margin-top: -12px;
}

.expenses-num div.heading {
  width: 75px;
  display: inline-block;
}

.qty-number input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
  width: 40px;
  height: 20px;
  text-align: center;
  border: 0;
}

.accordionClient button.accordion-button.collapsed,
.accordionClient .accordion-button:focus,
.accordionClient .accordion-button {
  background:#06a180;
  color: white;
  padding: 12px;
  border-color:#06a180;
  box-shadow: none;
}

.accordion-button::after {
  content: "\f229";
  background-image: none;
  font-family: bootstrap-icons !important;
}

.accordion-button:not(.collapsed)::after {
  background-image: none;
  font-family: bootstrap-icons !important;
  transform: rotateX(-180deg);
  margin-top: -5px;
}

.modal-xxl {
  max-width: 90%;
}

div#add_customer_model,
div#delete-inner-model {
  z-index: 11111111111111111111111111111111111;
  background: #00000078;
}

.upload-osr-file {
  width: 55%;
  margin: 0 auto;
}

.search-certificate-label {
  align-items: center;
  /* justify-content: center; */
  display: flex;
  margin-right: 10px;
}

.search-certificate-result td {
  vertical-align: middle;
}
.search-certificate-result td,
.search-certificate-result th {
  padding-left: 10px;
}

#sidebar {
  width: 15%;
  position: fixed;
  left: 0;
  bottom: 0;
  top: 75px;
  height: calc(100vh + -75px);
  transition: all 0.12s;
  overflow-y: auto;
  background: white;
  z-index: 222;
}

/* web toast css start  */

@keyframes slideRight {
  0% {
    transform: translateX(100px);
  }

  70% {
    transform: translateX(-30px);
  }

  100% {
    transform: translateX(0px);
  }
}

@keyframes slideLeft {
  0% {
    transform: translateX(-100px);
  }

  70% {
    transform: translateX(30px);
  }

  100% {
    transform: translateX(0px);
  }
}

@keyframes slideDown {
  0% {
    transform: translateY(-100px);
  }

  70% {
    transform: translateY(30px);
  }

  100% {
    transform: translateY(0px);
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(100px);
  }

  70% {
    transform: translateY(-30px);
  }

  100% {
    transform: translateY(0px);
  }
}

.toasttopright {
  left: initial;
  right: 20px;
  animation: slideRight 0.8s;
}

.toasttopleft {
  left: 20px;
  animation: slideLeft 0.8s;
}

.toasttopcenter {
  left: 0px;
  right: 0px;
  text-align: center;
  animation: slideDown 0.8s;
}

.toastbottomcenter {
  left: 0px;
  right: 0px;
  bottom: 20px;
  top: initial !important;
  text-align: center;
  animation: slideUp 0.8s;
}

.toastbottomleft {
  bottom: 20px;
  top: initial !important;
  animation: slideLeft 0.8s;
}

.toastbottomright {
  right: 20px;
  bottom: 20px;
  top: initial !important;
  animation: slideRight 0.8s;
}

.toastContainer {
  position: fixed;
  top: 20px;
  z-index: 999999;
}

.webToast {
  display: inline-block;
  text-align: left;
  padding: 10px 0;
  background-color: #fff;
  border-radius: 4px;
  max-width: 500px;
  top: 0px;
  position: relative;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  transition: all 0.8s ease-in;
  z-index: 99999;
}

.webToast:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.toastIcon {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: white;
}

.toastStatus {
  color: #3e3e3e;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 2px;
  font-size: 20px;
}

.toastMessage {
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 0;
  color: #878787;
}

.toastContent {
  padding-left: 60px;
  padding-right: 40px;
  max-width: 300px;
}

.toast__close {
  position: absolute;
  right: 15px;
  top: 38%;
  width: 14px;
  cursor: pointer;
  height: 14px;
  color: #ada9a9;
  transform: translateY(-50%);
  font-size: 28px;
}

.toastSuccess .toastIcon {
  background-color: #2bde3f;
}

.toastSuccess:before {
  background-color: #2bde3f;
}

.toastInfo .toastIcon {
  background-color: #1d72f3;
}

.toastInfo:before {
  background-color: #1d72f3;
}

.toastDanger .toastIcon {
  background-color: #ef6658;
}

.toastDanger:before {
  background-color: #ef6658;
}

/* web toast css end here    */
/* width */
#sidebar::-webkit-scrollbar {
  width: 5px;
}

/* Track */
#sidebar::-webkit-scrollbar-track {
  background: #000;
}

/* Handle */
#sidebar::-webkit-scrollbar-thumb {
  background:#06a180;
}

/* Handle on hover */
#sidebar::-webkit-scrollbar-thumb:hover {
  background:#06a180;
}

div#sidebar-nav {
  min-height: auto !important;
}

.card-logo-img {
  width: 106px;
}

.card-logo-table td {
  vertical-align: middle;
}

#sidebar.collapse:not(.show) {
  width: 0%;
  overflow: hidden;
  transition: all 0.12s;
  display: block !important;
}

.site-main-design {
  width: 84%;
  margin-left: auto;
  transition: all 0.5s;
  padding-bottom: 50px;
}

.site-main-design.full-show {
  width: 100%;
  transition: all 0.5s;
}

#sidebar .list-group-item:hover,
#sidebar .list-group-item.active,
#sidebar .list-group-item:focus {
  color: white;
  background:#06a180;
  border-color:#06a180;
}

a#menu-open-close {
  background:#06a180;
  color: white;
  padding: 5px 8px !important;
  font-size: 16px;
}
.bg-orange {
  background-color:#06a180 !important;
}

.border-orange {
  border-color:#06a180 !important;
}

.manage-btns {
  color: #ffffff;
  margin-right: 7px;
  margin-top: 10px;
  display: inline-block;
  border: 1px dotted#06a180;
  padding: 3px 7px;
  border-radius: 3px;
  font-size: 13px;
  background:#06a180;
}

.manage-btns:hover {
  background-color: #000000 !important;
  border-color: #000000 !important;
  color: white;
}

.site-footer-main {
  position: fixed;
  left: 15%;
  right: 0;
  width: 100%;
  bottom: 0;
  text-align: center;
  padding: 20px;
  width: calc(100% - 15%);
  /* transition: all 0.2s; */
  border-top: 1px solid #dadada;
  background: white;
  z-index: 222;
}
.site-footer-main.full-show {
  width: 100%;
  transition: all 0.2s;
  left: 0;
}

.color-enable {
  position: relative;
  padding: 30px;
  padding-top: 60px;
}

.loockup-table td,
.mail-manage td,
.vr-mid td {
  vertical-align: middle;
}

.certificate-details td a {
  display: block;
  color: #000;
}

.color-enable::before {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  content: "";
  opacity: 0.5;
  display: block;
  z-index: 1;
  background: rgb(0, 0, 0);
  transition: all 0.5s;
}

.color-enable.enab-color::before {
  z-index: -1;
  background: white;
  transition: all 0.5s;
}

.enablecolor-div {
  position: absolute;
  left: 15px;
  top: 10px;
  z-index: 11;
}

.enablecolor-div label {
  color: white;
}
.color-enable.enab-color .enablecolor-div label {
  color: initial;
}

.targets {
  display: flex;
  justify-content: space-evenly;
}

.target1 {
  border: 1px solid;
  margin: 0px 10%;
  width: 150px;
  height: 150px;
  display: inline-block;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: -webkit-linear-gradient(
    left,
    rgb(38, 194, 247),
    rgb(143, 93, 208) 5%,
    rgb(252, 48, 97) 13%,
    rgb(159, 134, 227) 19%,
    rgb(64, 167, 153) 24%,
    rgb(59, 6, 17) 32%,
    rgb(129, 36, 40) 37%,
    rgb(155, 194, 254) 41%,
    rgb(24, 194, 75) 48%,
    rgb(121, 3, 228) 54%,
    rgb(183, 125, 145) 57%,
    rgb(25, 138, 168) 58%,
    rgb(207, 127, 115) 62%,
    rgb(33, 141, 108) 79%,
    rgb(245, 36, 128) 86%,
    rgb(70, 195, 58) 99%
  );
}

.target2 {
  border: 1px solid;
  margin: 0px 10%;
  width: 150px;
  height: 150px;
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: -webkit-linear-gradient(
    left,
    rgb(38, 194, 247),
    rgb(143, 93, 208) 5%,
    rgb(252, 48, 97) 13%,
    rgb(159, 134, 227) 19%,
    rgb(64, 167, 153) 24%,
    rgb(59, 6, 17) 32%,
    rgb(129, 36, 40) 37%,
    rgb(155, 194, 254) 41%,
    rgb(24, 194, 75) 48%,
    rgb(121, 3, 228) 54%,
    rgb(183, 125, 145) 57%,
    rgb(25, 138, 168) 58%,
    rgb(207, 127, 115) 62%,
    rgb(33, 141, 108) 79%,
    rgb(245, 36, 128) 86%,
    rgb(70, 195, 58) 99%
  );
}

.targets span {
  background: #f8f8f8;
  border: 1px solid #ddd;
  padding: 2px;
  border-radius: 2px;
  font-size: 14px;
}

/* pre loader css  */
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: #ffffffb3;
  z-index: 1111111111111111111;
  display: none;
}

.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #3e3934;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}

.error {
  color: #06a180  !important;
}

.inner-job-table {
  width: 100%;
  border: 1px solid #3e3b3b;
}
.inner-job-table tr {
  background: white;
}
.inner-job-table tr:nth-child(2n + 1) {
  background-color: #f2f2f2;
}

.inner-job-table tr td {
  padding: 8px 10px;
}

.on-off-cards .form-switch {
  padding: 0;
}
.on-off-cards .form-switch .form-check-input {
  margin: 0 auto;
  display: block;
  float: none;
  width: 40px;
  height: 20px;
}

.on-off-cards .form-switch label {
  line-height: 2.5;
  font-size: 16px;
  width: 60%;
  display: block;
  margin: 0 auto;
  margin-bottom: 35px;
}

.change-color-this {
  background-color: none;
  background: black;
}

select#ceckstatus {
  color: white !important;
  display: block;
  width: 100%;
  padding: 0.45rem 2.25rem 0.45rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  /* transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; */
}

.Juices {
  background-color: #f7efff;
}

.Shakes {
  background-color: #d4fcef;
}
.Salad {
  background-color: #ebfcd7;
}

.Sandwich {
  background-color: #d7fcda;
}
.Steaks {
  background-color: #faf6d9;
}
.Beverages {
  background-color: #fcd7d7;
}
.Breakfast {
  background-color: #ffded4;
}
.Fish {
  background-color: #ffead1;
}
.Parfaits {
  background-color: #fff5d1;
}
.Proteine {
  background-color: #d4e2fc;
}
.Fried {
  background-color: #fcd4ef;
}
.cafe-catagry-btn {
  border: 0;
  font-size: 15px;
  font-weight: 500;
  padding: 8px 24px;
  border-radius: 50px;
  color: #414141;
}

.cafe-product {
  margin-top: 8px;
  margin-right: 10px;
  width: 170px;
  min-height: 209px;
  float: left;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0 0.25rem 0.75rem rgb(18 38 63 / 8%);
  background: #fff;
}
.cafe-product img.img-fluid {
  width: 100%;
  height: 105px;
  object-fit: cover;
  border-radius: 10px;
}
.productcontent {
  margin-top: 15px;
}
.productcontent p {
  font-size: 16px;
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 20px;
}
.add-cart {
  width: 104px;
  height: 34px;
  font-size: 12px;
  color: #ffffff;
  border: 0;
  outline: 0;
  background:#06a180;
  border-radius: 5px;
}
.add-cart:hover {
  background: #000000;
}

.cart-cafe {
  background: transparent !important;
  padding: 0;
  border: 0;
  outline: 0;
  margin-right: 20px;
  position: relative;
  color: black !important;
}
.cart-cafe.btn.show,
.cart-cafe.btn:active {
  color: black !important;
}

.cart-cafe.dropdown-toggle::after {
  content: none;
}

.cart-cafe span {
  position: absolute;
  width: 20px;
  height: 20px;
  background:#06a180;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 10px;
  font-weight: 700;
  right: -4px;
  top: -3px;
}

.cart-cafe-item {
  padding: 20px;
  display: flex;
  align-items: center;
  position: relative;
}
.cart-cafe-item img {
  width: 71px;
  height: 71px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 20px;
}
.cart-cafe-item h5 {
  font-size: 16px;
  font-weight: 400;
  color: #272727;
  line-height: 1.5;
}
.cart-cafe-item h5 span {
  display: block;
  font-size: 14px;
  color:#06a180;
  font-weight: 500;
}
.cafe-cart-dropdown {
  min-width: 350px;
}
button.remove-item-cafe {
  padding: 0;
  border: 0;
  outline: 0;
  position: absolute;
  right: -8px;
  top: -17px;
  font-size: 20px;
  color: #272727;
  background: white;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  text-align: center;
  display: flex;
  align-items: center;
}

.cafe-cart-subtotal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  padding: 0px 20px;
  font-size: 18px;
}
.cafe-cart-subtotal span {
  color:#06a180;
}
.cart-cafe-actions {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}
.cart-cafe-actions a {
  width: 48%;
  height: 45px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cart-cafe-actions a:last-child {
  color: #444444;
  box-shadow: -1px -1px 0.75rem rgb(18 38 63 / 8%);
}
.cart-cafe-actions a:first-child {
  background: #06a180 ;
  color: white;
}
.cart-cafe-actions a:hover {
  background: #000;
  color: white;
}

.cart-detail-table th {
  padding: 30px 20px;
}
.cart-detail-table td {
  padding: 38px 30px;
}

.cart-detail-table .orderlist .product-box img {
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.cart-detail-table button.remove-item-cafe {
  color: #757575;
  font-size: 24px;
  top: 30px;
}
.cart-detail-table .orderlist .product-title {
  font-size: 16px;
  font-weight: 400;
  color: #272727;
  line-height: 1.5;
  margin-left: 10px;
}
.cart-detail-table .orderlist .product-title span {
  display: block;
  font-size: 14px;
  color:#06a180;
  font-weight: 500;
}

.cart-detail-table .qty-number .minus,
.cart-detail-table .qty-number .plus {
  width: 45px;
  height: 50px;
  display: inline-flex;
  vertical-align: top;
  align-items: center;
  justify-content: center;
  font-size: 31px;
  border: 1px solid #ddd;
  cursor: pointer;
  user-select: none;
}

.cart-detail-table .qty-number input {
  width: 45px;
  height: 50px;
  text-align: center;
  font-size: 18px;
  border: 1px solid #ddd;
  display: inline-block;
  border-left: 0px;
  border-right: 0px;
}

.cart-detail-side td {
  padding: 30px 15px;
  color: #6d6d6d;
  font-size: 16px;
}
table.table.align-middle.mb-0.border.cart-detail-side.caption-top caption {
  font-size: 32px;
  font-weight: 700;
  padding: 0;
  margin-bottom: 15px;
}
a.add-cart.cart-proceed {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  margin-top: 15px;
}
@media (min-width: 1200px) and (max-width: 1600px) {
  /* .catagry-btn {
    width: 31%;
    margin-bottom: 10px;
  } */
}

@media (min-width: 1200px) and (max-width: 1311px) {
  .card.radius-10.border-0.border-start.border-orange.border-3 .card-body {
    padding: 1rem 0.5rem;
  }
  .manage-btns {
    color: #ffffff;
    margin-right: 2px;
    margin-top: 5px;
    display: inline-block;
    border: 1px dotted #3e3934;
    padding: 3px 5px;
    border-radius: 3px;
    font-size: 13px;
    background: #3e3934;
  }
  .chooce-one .tabs-nav {
    margin-bottom: 15px;
  }

  .sidebar-wrapper .metismenu a {
    padding: 9px 7px;
    font-size: 14px;
  }

  .sidebar-wrapper .metismenu a .parent-icon {
    font-size: 14px;
    line-height: 1;
  }

  .top-head-nav a.nav-link.btn.btn-dark {
    padding-right: 0.6rem !important;
    padding-left: 0.6rem !important;
  }
  .metismenu .has-arrow:after {
    right: 0px;
  }

  .customer-header.top-header .navbar {
    padding: 0 10px;
  }

  .card.radius-10.border-0.border-start.border-orange.border-3
    .card-body
    .widget-icon {
    width: 40px;
    height: 40px;
  }

  .sidebar-wrapper .metismenu .mm-active > a,
  .sidebar-wrapper .metismenu a:active,
  .sidebar-wrapper .metismenu a:focus,
  .sidebar-wrapper .metismenu a:hover {
    border-left: 2px solid#06a180;
  }

  .card.radius-10.border-0.border-start.border-orange.border-3
    .card-body
    h4.mb-1 {
    font-size: 22px;
  }

  .on-off-cards .form-switch label {
    width: 80%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .upload-osr-file {
    width: 90%;
    margin: 0 auto;
  }

  .search-certificate-result button.btn.btn-primary {
    font-size: 14px;
  }
  .quick input {
    font-size: 16px;
  }

  .custom-logo img {
    width: 150px;
  }

  .quick input {
    width: 49%;
  }

  .quick .want-wht input {
    width: 49%;
  }

  .customer-header.top-header .navbar {
    padding: 0;
  }

  .on-off-cards .form-switch label {
    width: 80%;
  }

  #sidebar a.list-group-item {
    font-size: 12px;
    padding: 5px;
  }

  .card.radius-10.border-0.border-start.border-orange.border-3
    .card-body
    h4.mb-1 {
    font-size: 15px;
  }

  .card.radius-10.border-0.border-start.border-orange.border-3
    .card-body
    h4.mb-1
    .widget-icon {
    width: 50px;
    height: 44px;
  }

  .manage-btns {
    color: #ffffff;
    margin-right: 9px;
    margin-top: 10px;
    display: inline-block;
    border: 1px dotted #3e3934;
    padding: 3px 9px;
    border-radius: 3px;
    font-size: 12px;
    background: #3e3934;
  }

  .top-head-nav a.nav-link.btn.btn-dark {
    background-color:#06a18000;
    border-color: #000000;
    color: #000000;
    /* padding: 0.5rem 1rem; */
    padding-right: 10px !important;
    padding-left: 10px !important;
    font-size: 13px;
  }
  .top-head-nav a.nav-link.btn.btn-dark i {
    margin-left: 0px;
  }

  #sidebar {
    width: 20%;
  }

  .site-main-design {
    width: 80%;
  }

  .sidebar-wrapper .metismenu a {
    padding: 8px 7px;
    font-size: 14px;
  }

  .sidebar-wrapper .metismenu a .parent-icon {
    font-size: 14px;
  }

  .metismenu .has-arrow:after {
    right: 4px;
  }

  .sidebar-wrapper .metismenu .mm-active > a,
  .sidebar-wrapper .metismenu a:active,
  .sidebar-wrapper .metismenu a:focus,
  .sidebar-wrapper .metismenu a:hover {
    border-left: 2px solid#06a180;
  }

  .widget-icon {
    width: 35px;
    height: 35px;
    background-color: #fafafa;
    border-radius: 50%;
    font-size: 14px;
  }
  .chooce-one .tabs-nav {
    margin: 0 auto;
    padding: 0;
    max-width: 370px;
    margin-bottom: 20px;
  }

  .report-filters .nav-danger.nav-tabs .nav-link {
    padding: 5px 8px;
    font-size: 12px;
  }

  .exp-type .custom-raio ul li {
    display: block;
    width: 100%;
    margin-right: 0;
  }

  .exp-type .custom-raio ul {
    margin-top: 0;
  }

  .catagry-icon {
    margin-right: 0px;
    width: 24px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .top-header
    .navbar
    .dropdown-large
    a.nav-link.dropdown-toggle.dropdown-toggle-nocaret {
    padding: 7px 10px;
  }

  .upload-osr-file {
    width: 100%;
    margin: 0 auto;
  }
  .search-certificate-result button.btn.btn-primary {
    font-size: 12px;
  }

  #sidebar {
    width: 0%;
    z-index: 111111;
    top: 84px;
  }

  .setting-box {
    position: absolute;
    right: -1px;
    min-width: 650px;
  }

  .quick input {
    width: 49%;
    font-size: 16px;
  }

  .quick .want-wht input {
    width: 49%;
  }

  .tabs.chooce-one {
    margin-top: 0px;
  }

  .chooce-one .tabs-nav {
    margin-bottom: 15px;
  }

  .on-off-cards .form-switch label {
    width: 70%;
  }

  #sidebar.collapse:not(.show) {
    width: 35%;
    overflow-y: auto;
    z-index: 555;
    top: 84px;
  }

  a#menu-open-close {
    float: right;
  }
  .site-main-design {
    width: 100%;
  }

  .site-footer-main {
    width: 100%;
    left: 0;
  }

  header.top-header.customer-header
    nav.navbar.navbar-expand-lg
    ul.navbar-nav
    li.nav-item.active
    .nav-link {
    border-bottom: 0px solid #f7f8fa;
  }

  header.top-header.customer-header
    nav.navbar.navbar-expand-lg
    ul.navbar-nav
    li.nav-item:first-child
    > .nav-link {
    border-left: 0px solid #d2d4d7;
  }

  header.top-header.customer-header
    nav.navbar.navbar-expand-lg
    ul.navbar-nav
    li.nav-item
    .nav-link {
    border-right: 0px solid #d2d4d7;
  }

  .top-head-nav {
    max-height: 80vh;
    overflow: auto;
  }

  .navbar-toggler {
    padding: 5px 8px;
    font-size: 1.25rem;
    line-height: 1;
    background-color:#06a180;
    border: 1px solid#06a180;
    border-radius: 0.25rem;
    transition: box-shadow 0.15s ease-in-out;
    color: white;
    font-size: 24px;
  }

  .customer-header.top-header .navbar {
    left: 0;
    height: auto;
    padding: 8px 0px;
  }

  .top-head-nav a.nav-link.btn.btn-dark {
    border: none;
    border-radius: 0;
    text-align: right;
  }

  .top-head-nav a.nav-link.btn.btn-dark:hover,
  .top-head-nav a.nav-link.btn.btn-dark:focus,
  .top-head-nav li.nav-item.active a.nav-link.btn.btn-dark {
    background-color: #ffffff;
    color:#06a180;
  }

  .reports-all-main .nav.nav-tabs .nav-link {
    font-size: 15px;
    padding: 0.5rem 0.5rem;
  }

  .report-filters .nav-danger.nav-tabs .nav-link {
    padding: 5px 7px;
    font-size: 13px;
  }

  .exp-type .custom-raio ul li {
    display: block;
    width: 100%;
    margin-right: 0;
  }

  .exp-type .custom-raio ul {
    margin-top: 0;
  }
  .catagry-icon {
    margin-right: 5px;
    width: 50px;
  }
  .cafe-product {
    margin-top: 30px;
    width: 100%;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .mb-mob {
    margin-bottom: 10px;
  }
  .card-header h5.mb-0 .btn-primary {
    float: none !important;
    display: block !important;
    margin-top: 5px;
    margin-left: auto;
  }
  .custom-logo img {
    width: 160px;
  }
  .user-changepass {
    width: 190px;
  }
  .site-footer-main {
    width: 100%;
    left: 0;
  }
  .top-header
    .navbar
    .dropdown-large
    a.nav-link.dropdown-toggle.dropdown-toggle-nocaret {
    padding: 5px 9px;
  }
  .filter-search select.form-select {
    margin-bottom: 10px;
  }

  .expenses-num {
    margin-bottom: 10px;
  }

  .top-head-nav {
    max-height: 90vh;
    overflow: auto;
  }

  header.top-header.customer-header
    nav.navbar.navbar-expand-lg
    ul.navbar-nav
    li.nav-item
    .nav-link {
    border-right: 0px solid #d2d4d7;
    background: #f7f7f7;
    margin-top: 3px;
  }

  header.top-header.customer-header
    nav.navbar.navbar-expand-lg
    ul.navbar-nav
    li.nav-item.active
    .nav-link {
    border-bottom: 0px solid #f7f8fa;
  }

  header.top-header.customer-header
    nav.navbar.navbar-expand-lg
    ul.navbar-nav
    li.nav-item:first-child
    > .nav-link {
    border-left: 0px solid #d2d4d7;
  }

  .custom-raio ul {
    justify-content: space-around;
  }

  .quick input {
    width: 49%;
    font-size: 16px;
  }

  .quick .want-wht input {
    width: 49%;
  }
  .site-main-design {
    width: 100%;
  }
  #sidebar {
    width: 0%;
    z-index: 111;
    top: 69px;
  }

  a#menu-open-close {
    float: right;
  }

  #sidebar.collapse:not(.show) {
    width: 70%;
    overflow-y: auto;
    z-index: 555;
    top: 69px;
    height: calc(100vh + -69px);
  }

  .targets {
    display: block;
  }

  .target1,
  .target2 {
    margin: 20px auto;
  }

  .chooce-one .tabs-nav {
    max-width: 320px;
    margin-bottom: 18px;
  }

  .tabs.chooce-one {
    margin-top: 0px;
  }

  .customer-header.top-header .navbar {
    left: 0;
    height: auto;
    padding: 8px 0px;
  }

  .top-head-nav a.nav-link.btn.btn-dark {
    border: none;
    border-radius: 0;
    text-align: right;
  }

  .top-head-nav a.nav-link.btn.btn-dark:hover,
  .top-head-nav a.nav-link.btn.btn-dark:focus,
  .top-head-nav li.nav-item.active a.nav-link.btn.btn-dark {
    background-color: #ffffff;
    color:#06a180;
  }

  .navbar-toggler {
    padding: 3px 6px;
    font-size: 1.25rem;
    line-height: 1;
    background-color:#06a180;
    border: 1px solid#06a180;
    border-radius: 0.25rem;
    /* transition: box-shadow 0.15s ease-in-out; */
    color: white;
    font-size: 24px;
  }

  .page-content.customer-contnent {
    padding: 15px;
  }

  .reports-all-main .nav {
    display: block;
  }

  .reports-all-main .nav li {
    width: auto;
    display: inline-block;
  }
  .reports-all-main .nav.nav-tabs .nav-link i {
    font-size: 20px;
  }
  .reports-all-main .nav.nav-tabs .nav-link {
    font-size: 12px;
    padding: 9px;
  }

  .reports-all-main .nav-tabs .nav-link.active {
    border-left-color: #f7f8fa;
    border-bottom-color: black;
  }
  .report-filters .nav-danger.nav-tabs .nav-link {
    padding: 3px 5px;
    font-size: 12px;
    margin-bottom: 5px;
    font-weight: 600;
  }

  .graph-pic {
    margin-right: 15px;
    width: 50%;
  }

  .exp-type .custom-raio ul li {
    display: block;
    width: 100%;
    margin-right: 0px;
  }

  .exp-type .custom-raio ul {
    margin-top: 0;
  }

  .setting-box {
    min-width: 300px;
  }
  .top-header .navbar .dropdown-large .dropdown-menu {
    width: 360px !important;
  }

  .catagry-icon {
    margin-right: 5px;
    width: 50px;
  }
  .cafe-product {
    margin-top: 30px;
    width: 100%;
  }
}

@media (min-width: 320px) and (max-width: 575px) {
  .manage-btns {
    color: #ffffff;
    margin-right: 7px;
    margin-top: 10px;
    display: block;
    border: 1px dotted #3e3934;
    padding: 3px 7px;
    border-radius: 3px;
    font-size: 12px;
    background: #3e3934;
    max-width: 130px;
    width: fit-content;
    text-align: center;
  }
  .col-12 button.btn.btn-primary.float-end.mt-2 {
    float: none !important;
    width: 100%;
    display: block;
    margin-top: 20px !important;
  }

  .sidebar-wrapper .metismenu a {
    padding: 10px 10px;
    font-size: 14px;
  }

  .metismenu .has-arrow:after {
    right: 0px;
  }

  .top-header .navbar .dropdown-large .dropdown-menu {
    width: 300px !important;
  }
  .catagry-icon {
    margin-right: 5px;
    width: 50px;
  }
  .cafe-product {
    margin-top: 30px;
    width: 100%;
  }
}

@media (min-width: 320px) and (max-width: 367px) {
  /* .custom-logo img {
		width: 100px;
	} */
  .user-changepass {
    width: 173px;
    padding: 3px;
    height: 51px;
  }

  .widget-icon {
    width: 40px;
    height: 40px;
    background-color: #fafafa;
    border-radius: 50%;
    font-size: 15px;
  }

  .on-off-cards .form-switch label {
    width: 60%;
  }
}

/* customer-index pagee css custom end here  */

.custom-shadow {
  box-shadow: 0 0.25rem 0.75rem rgb(18 38 63 / 8%);
}
.dashboard-items h4 {
  font-size: 16px;
}
.dashboard-items span {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}
.custom-shadow:hover {
  box-shadow: 0 0.25rem 0.75rem rgb(18 38 63 / 33%);
}

.custom-group label {
  font-weight: normal;
  margin-bottom: 3px;
}
.form-label-1{
  font-size: 18px;
}
.custom-group .form-control {
  padding: 6px 10px;
  height: 40px;
  border-radius: 2px;
  border: 1px solid #e3e1e1;
  font-size: 14px;
}
.card {
  box-shadow: 0 0.25rem 0.75rem rgb(18 38 63 / 8%);
}
.accordionClient .accordion-item {
  border: none;
}
.accordionClient .accordion-collapse {
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.custom-multi-button .generate_report {
  margin-top: 3px;
  font-size: 18px;
  border-radius: 5px;
  margin-left: 3px;
}
.custom-group .form-select {
  padding: 6px 10px;
  height: 40px;
  border-radius: 2px;
  border: 1px solid #e3e1e1;
  font-size: 14px;
}
.bg-light-warning {
  box-shadow: 0 0.25rem 0.75rem rgb(18 38 63 / 8%);
}
.pricing-list a {
  transition: background-color 2s ease-out 100ms;
}
.pricing-list a span {
  display: block;
  text-align: right;
}
.btn-outline-secondary {
  transition: background-color 1s ease-out 50ms;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color:#06a180;
  border-color:#06a180;
}
.table-striped > tbody > tr:nth-of-type(odd) {
  --bs-table-accent-bg: #f3f8fb;
  --bs-table-striped-bg: #f3f8fb;
}
.navbar-nav .nav-item:last-child .nav-link {
  border-right: none !important;
}
.paging_simple_numbers {
  margin-top: 20px !important;
}
/* .sidebar-wrapper .metismenu .mm-active > a {
  border-left: 0;
} */
.btn-danger {
  border-radius: 2px;
}

/* Works on Firefox */
.card-body * {
  scrollbar-width: thin;
  scrollbar-color:#06a180 #000;
}

/* Works on Chrome, Edge, and Safari */
.card-body *::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}

.card-body *::-webkit-scrollbar-track {
  background: #000;
}

.card-body *::-webkit-scrollbar-thumb {
  background-color:#06a180;
}

.table-hover > tbody > tr:hover {
  --bs-table-accent-bg: rgb(237 28 36);
  color: #ffffff;
}
.table-hover > tbody > tr:hover .price-tag {
  color: #000;
}
.client-search-portion .btn-primary {
  border-radius: 0 !important;
}
.client-search-portion .btn-primary:last-child {
  border-top-right-radius: 2px !important;
  border-bottom-right-radius: 2px !important;
}

.table-style-1 thead th {
  cursor: pointer;
  padding: 15px 13px;
  padding-left: 35px !important;
  position: relative;
  background-color:#06a180;
  color: white;
}

.table-style-1 thead.bg-success th {
  background-color: #00a352 !important;
}
.table-style-1 thead.bg-#06a180  th {
  background-color: #ffe3e5 !important;
}
.table-style-1 thead.bg-black th {
  background-color: #000 !important;
}

.table-style-1 thead th.no_sort {
  padding-left: 10px !important;
  cursor: initial;
}

.table-style-1 thead {
  background-color:#06a180;
  color: #fff;
}

.table-style-1 thead th.ascending:after,
.table-style-1 thead th:after {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYEAQAAAAa7ikwAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAAAGAAAABgAPBrQs8AAAAHdElNRQfmCg4HIDeO0KqFAAAA8UlEQVRIx+1UMQqDQBCcExF/ooSQ3yiSn9jZGPMGn5ImXUSw0s7GxsI3iGRShGBOiTmDNsHpbu925m6ZG2CDCkjXJbuOs5DnMkdRkJ435Nafu7cbxOkEGIb6tWQBYL8HbHvFKZBkEAzr2mqK/ylA5vl393Qd746jKqDLy/MZOBymW9oWSJK1Xz7C5qLlBKZzaRgP36GPKpO5NF9gMaziIjLLyCiSa5ZFluXrM+q/Ub/YLhcI3ydN81mwLOB6BUiILFtoNGEoG6FpyN1OofE9l6bzpxcZk4vPTcdjn0ttC8ax0Or683nPA9JUiKpaZDSqeAA1DweRxoLftQAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMi0xMC0xNFQwNzozMjo1NSswMDowMO6AglcAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjItMTAtMTRUMDc6MzI6NTUrMDA6MDCf3TrrAAAAKHRFWHRkYXRlOnRpbWVzdGFtcAAyMDIyLTEwLTE0VDA3OjMyOjU1KzAwOjAwyMgbNAAAAABJRU5ErkJggg==);
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  float: left;
  height: 18px;
  position: absolute;
  left: 4px;
  top: 18px;
  width: 18px;
}

.table-style-1 thead th.ascending:after {
  opacity: 1;
}

.table-style-1 thead th.descending:after {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYEAQAAAAa7ikwAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAAAGAAAABgAPBrQs8AAAAHdElNRQfmCg4HIQlWqoZvAAABAElEQVRIx92UMQ6CQBBFZzYeg8ZGEgoK74LCbai09Bgk3MTGBDptpEDDIch+ixXJBseogAm+hizMzmf/Tj7RyLD0AVitiOZzs9CaKElYlaVcv14T7ffMRfGWMpDneFDX0EEg1urNxtRVFeB5g1rQNm8YUKRtvtuZZ5IA1ytwuQB3e/sJHA7AdmssBYA4BlwXOJ0aS2d9BFgtl513fDwSLRbNuiMA7ThEUUSsVLfl+cycpj2PHQRAXeMpWSbuu1vU13b5xwQB9UWvj5i+gDVFVv5IvJFL8l4rfySe55J0ydYJmH1/aIumf8m/maLX+SPxQS69zh8JO5fMBIbh2I78ITdlKiaFlQJYfwAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMi0xMC0xNFQwNzozMzowOSswMDowMI4CjXkAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjItMTAtMTRUMDc6MzM6MDkrMDA6MDD/XzXFAAAAKHRFWHRkYXRlOnRpbWVzdGFtcAAyMDIyLTEwLTE0VDA3OjMzOjA5KzAwOjAwqEoUGgAAAABJRU5ErkJggg==);
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  float: left;
  height: 18px;
  opacity: 1;
  position: absolute;
  left: 4px;
  top: 18px;
  width: 18px;
}

.table-style-1 thead th.no_sort:after {
  content: none;
}

.loader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #ffffff9e;
  z-index: 1111111111111111;
}
.loader.in {
  display: flex;
}
.loader .main-spin {
  width: 70px;
  height: 70px;
  border-color:#06a180;
  border-right-color: transparent;
}

/* 404 page css  */
#notfound {
  position: fixed;
  min-height: 100vh;
  width: 100%;
  background: white;
  z-index: 111111111111111;
}

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
#notfound .notfound {
  max-width: 920px;
  width: 100%;
  line-height: 1.4;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
}
#notfound .notfound .notfound-404 {
  position: absolute;
  height: 100px;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: -1;
}
#notfound .notfound .notfound-404 h1 {
  color: #ffe8ed;
  font-weight: 900;
  font-size: 276px;
  margin: 0px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
#notfound .notfound h2 {
  font-size: 46px;
  color: #000;
  font-weight: 900;
  text-transform: uppercase;
  margin: 0px;
}
#notfound .notfound p {
  font-size: 16px;
  color: #000;
  font-weight: 400;
  text-transform: uppercase;
  margin-top: 15px;
}
#notfound .notfound button {
  font-size: 14px;
  text-decoration: none;
  text-transform: uppercase;
  background:#06a180;
  display: inline-block;
  padding: 16px 38px;
  border: 2px solid transparent;
  border-radius: 40px;
  color: #fff;
  font-weight: 400;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}
#notfound .notfound button:hover {
  background-color: #fff;
  border-color:#06a180;
  color:#06a180;
}

@media only screen and (max-width: 480px) {
  #notfound .notfound .notfound-404 h1 {
    font-size: 162px;
  }
  #notfound .notfound h2 {
    font-size: 26px;
  }
  #notfound .notfound p {
    font-size: 14px;
  }
  #notfound .notfound button {
    font-size: 12px;
    padding: 5px 20px;
  }
}

/* Unauthorized */

#unauthorized {
  margin: 0;
  padding: 0;
  text-align: center;
}
#unauthorized h1,
a {
  margin: 0;
  padding: 0;
  text-decoration: none;
}
#unauthorized .section .error {
  font-size: 154px;
  color:#06a180;
  text-shadow: 1px 1px 1px#06a180, 2px 2px 1px#06a180, 3px 3px 1px#06a180,
    4px 4px 1px#06a180, 5px 5px 1px#06a180, 6px 6px 1px#06a180,
    7px 7px 1px#06a180, 8px 8px 1px#06a180, 25px 25px 8px rgba(0, 0, 0, 0.2);
}

#unauthorized .page {
  margin: 2rem 0;
  font-size: 20px;
  font-weight: 600;
  color: #444;
}

#unauthorized .back-home {
  display: inline-block;
  border: 2px solid#06a180;
  color:#06a180;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0.75rem 1rem 0.6rem;
  transition: all 0.2s linear;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
}
#unauthorized .back-home:hover {
  background:#06a180;
  color: #fff;
}

.finance-btn {
  color: #06a180 ;
  font-weight: 700;
  background-color: #06a18014;
  border-color: #06a18014;
  box-shadow: none;
  border-radius: 2px !important;
  font-size: 14px;
}
.finance-btn:hover {
  background-color: #06a180 ;
  color: #fff;
}
.finance-btn.active {
  color: #fff;
}
tr.row-highlight td {
  background:#06a1800f;
}

@media print {
  body {
    margin: 0;
    padding: 0;
  }
  /* Add more rules to reset margins/paddings for other elements if needed */
}
/* Fitness- module */
.fitness-btn {
  background: #edf5f8;
  color: #000;
  border: 0;
  border-radius: 38px;
  padding: 5px 20px 5px 20px;
}
.fitness-btn:hover {
  background:#06a180;
  color: #fff;
  border: 0;
}
.fitness-info {
  background: #fff;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  text-align: center;
}
.fitness-card {
  position: relative;
  border-radius: 10px;
}

.arrow-at-top {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -36px;
  margin: 0 auto;
  display: block;
  text-align: center;
  font-size: 38px;
}
.custom-fitness-col {
  border-right: 1px solid #848484;
}
.day1 {
  background: #d0e5e7;
  border-radius: 10px;
}
.day1 .arrow-at-top {
  color: #d0e5e7;
}

.day2 {
  background: #ffd6a0;
  border-radius: 10px;
}
.day2 .arrow-at-top {
  color: #ffd6a0;
}

.day3 {
  background: #ffb6ad;
  border-radius: 10px;
}
.day3 .arrow-at-top {
  color: #ffb6ad;
}
.day4 {
  background: #c5d7c7;
  border-radius: 10px;
}
.day4 .arrow-at-top {
  color: #c5d7c7;
}

.fitness-items.d-flex {
  white-space: nowrap;
  overflow-x: auto;
}

.fitness-main::-webkit-scrollbar {
  width: 0;
  height: 0;
}
/* Track */
.fitness-main::-webkit-scrollbar-track {
  background: transparent;
}
/* Handle */
.fitness-main::-webkit-scrollbar-thumb {
  background: transparent;
}
/* Handle on hover */
.fitness-main::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

.fitness-items::-webkit-scrollbar {
  width: 0;
  height: 0;
}
/* Track */
.fitness-items::-webkit-scrollbar-track {
  background: transparent;
}
/* Handle */
.fitness-items::-webkit-scrollbar-thumb {
  background: transparent;
}
/* Handle on hover */
.fitness-items::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

/* detail fitness plan  */

#training .btn {
  border-radius: 50px;
  font-size: 15px;
  padding: 9px 22px;
  border: none !important;
  color: black !important;
  background-color: #edf5f8 !important;
  transition: background-color 0.3s ease, color 0.3s ease;
}
#training .active {
  background-color: #e7002a !important;
  color: white !important;
}
.training_card .exercise_type {
  color: #9a9a9a;
  font-weight: 700;
  font-size: 14px;
}
.training_card h6.me-auto {
  color:#06a180;
  font-weight: 700;
  font-size: 14px;
}

.training_card .black_clr {
  font-size: 14px !important;
  margin-bottom: 0px;
  margin-top: 8px;
  font-weight: 700;
}
.training_card {
  min-height: 100%;
  padding: 23px;
  width: 90%;
  border-radius: 10px;
  box-shadow: 0px 0px 19px -6px #dfdfdf;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.training_card.active {
  background-color: #e7002a;
  color: white !important;
  cursor: initial;
}
.training_card.active .black_clr,
.training_card.active h6.me-auto,
.training_card.active .exercise_type {
  color: white;
}

.training_card svg {
  position: absolute;
  left: -11px;
  top: -7px;
  height: 35px;
  width: 35px;
}
.training_card.active svg {
  color: white;
}

.training_card ul {
  padding-left: 0px;
  margin-top: 8px;
  list-style: none;
  margin-bottom: 0px !important;
}
#training table tbody ul {
  padding-left: 0px;
  margin-top: 5px;
  list-style: none;
  margin-bottom: 0px !important;
}
.training_card ul li,
#training table tbody ul li {
  padding-left: 18px;
  position: relative;
  display: inline-block;
  padding-right: 30px;
  margin-bottom: 5px;
}

/* Your existing styles for .table_wrapper */
#training .table_header {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-color: #384053;
  padding: 15px;
  color: white;
}
#training .table_header h6 {
  margin-bottom: 0 !important;
}
#training table {
  width: 100%;
  border-bottom-left-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
  overflow-x: auto;
}
#training table thead tr {
  background-color: #e7002a !important;
}
#training table th {
  color: white;
  padding: 15px;
}
#training table td {
  padding: 15px;
}
#training table tbody tr {
  background-color: #f2f8fa;
}

/* Hover row color */
#training table tbody tr:hover {
  /* background-color: #d3f7fa !important; */
  color: rgb(0, 0, 0);
}
#training table tbody svg {
  color: #e7002a;
  position: absolute;
  left: -11px;
  top: -8px;
  height: 35px;
  width: 35px;
}

/* slider */
@supports (display: grid) {
  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto 1fr auto;
    grid-template-areas: "header header header" "title title footer" "main main main";
  }
  @media screen and (max-width: 500px) {
    .grid-container {
      grid-template-columns: 1fr;
      grid-template-rows: 0.3fr 1fr auto 1fr;
      grid-template-areas: "header" "title" "main" "footer";
    }
  }
  .fitness-main {
    color: #959595;
    /* background-color: white; */
    grid-area: main;
    padding: 0;
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .fitness-items {
    position: relative;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    transition: all 0.2s;
    transform: scale(0.98);
    will-change: transform;
    user-select: none;
    cursor: pointer;
  }

  .fitness-items.active {
    background: rgba(255, 255, 255, 0.3);
    cursor: grabbing;
    cursor: -webkit-grabbing;
    transform: scale(1);
  }
  .item {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fff;
    min-height: 90px;
    min-width: 90px;
    border-radius: 5px;
    margin: 1em;
    padding: 10px;
    text-align: center;
    box-shadow: 0 0.25rem 0.75rem rgb(18 38 63 / 8%);
  }

  .item img {
    max-width: 100%;
    max-height: 100%;
  }
  .item:hover,
  .item.actived {
    border: 1px solid#06a180;
    background: #06a18014;
    box-shadow: 0 0.25rem 0.75rem rgba(18, 38, 63, 0.33);
  }

  @media screen and (max-width: 500px) {
    .item {
      min-height: 200px;
      min-width: 200px;
    }
  }
}

.cafe-card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}
.cafe-product-price {
  font-size: 15px;
  font-weight: 700;
}
.cafe-product-name {
  font-weight: 500;
  font-size: 13px;
  height: 42px;
  display: block;
  display: -webkit-box;
  max-width: 100%;
  height: 43px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cafe-product:hover {
  box-shadow: 0 0.25rem 0.75rem rgba(18, 38, 63, 0.33);
}
.cafe-slider-text {
  font-size: 13px;
}
@media only screen and (max-width: 1025px) {
  .cafe-product-name {
    font-weight: 500;
    font-size: 13px;
    height: 42px;
    display: block;
    display: -webkit-box;
    max-width: 100%;
    height: 43px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .cafe-product-price {
    font-size: 15px;
    font-weight: 600;
  }
  .cafe-product img.img-fluid {
    width: 100%;
    height: 90px;
    object-fit: cover;
    border-radius: 10px;
  }
  .cafe-product {
    margin-top: 20px;
    width: 18%;
    min-height: 200px;
    float: left;
    padding: 10px;
    border-radius: 10px;
  }
}
#cartbar {
  width: 0%;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 75px;
  height: calc(100vh + -75px);
  transition: all 0.12s;
  overflow-y: auto;
  background: white;
  z-index: 222;
}
#cartbar.show {
  width: 20%;
  padding: 20px;
}

#cartbar-nav {
  min-height: auto !important;
  position: relative;
}
#cartbar.collapse:not(.show) {
  width: 0%;
  overflow: hidden;
  transition: all 0.12s;
  display: block !important;
}
.site-main-design.full-show.site-main-design2 {
  margin-left: 0;
  width: 80%;
  transition: all 0.5s;
}
.orderlist {
  background: #f7f7f9;
  padding: 10px;
  border-radius: 5px;
}
.qty-number {
  cursor: pointer;

  background: white;
  width: 36%;
  height: 20px;
  text-align: center;
  align-items: center;
  font-size: 13px;
  display: flex;
  justify-content: center;
  border-radius: 5px;
}
.cart-title {
  height: 30px;
  width: 100%;
}
.cart-total {
  background: #f7f7f9;
  padding: 10px;
  border-radius: 5px;
}
.product-title span {
  font-size: 13px;
}
.qty-price {
  font-size: 13px;
  font-weight: 500;
  width: 39%;
}
.product-price {
  width: 44%;
}

.cafe-prdct-pric {
  width: 70%;
}
@media only screen and (max-width: 1025px) {
  .product-price {
    width: 58%;
    font-size: 10px;
  }
  .qty-price {
    font-size: 10px;
    font-weight: 500;
    width: 38%;
  }

  .qty-number input[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield;
    width: 20px;
    height: 15px;
    text-align: center;
    border: 0;
  }
  .site-main-design.full-show.site-main-design2 {
    margin-left: 0;
    width: 76%;
    transition: all 0.5s;
  }
  #cartbar.show {
    width: 25%;
    padding: 10px;
  }
  #cartbar {
    width: 0%;
    position: fixed;
    right: 0;
    bottom: 0;
    top: 75px;
    height: calc(100vh + -75px);
    transition: all 0.12s;
    overflow-y: auto;
    background: white;
    z-index: 222;
  }
}
.cafe-btn {
  border: none;
  border-radius: 30px;
  padding: 5px 7px 5px 7px;
  background: #06a18014;
  color: #06a180 ;
  font-weight: 600;
  font-size: 12px;
}
.cafe-btn:hover {
  border: 1px solid #06a180 ;
}
.cafe-btn.active {
  border: 1px solid #06a180 ;
}

.cafe-pay-button {
  justify-content: center;
  align-items: center;
  display: flex;
}
.cafe-pay-button button {
  background: #06a180 ;
  border-radius: 5px;
  border: 0;
  padding: 5px 95px 5px 95px;
  color: #fff;
}
.cafe-cart-heading {
  font-weight: 700;
  font-size: 18px;
  color: #000;
}
.cafe-btn-group {
  display: flex;
  /* align-items: center;
  justify-content: center; */
}

.close-cart {
  position: absolute;
  right: -20px;
  top: -25px;
  font-size: 25px;
  color:#06a180;
  cursor: pointer;
}
@media only screen and (max-width: 1025px) {
  .cafe-btn {
    border: none;
    border-radius: 30px;
    padding: 4px 25px 4px 25px;
    background: #06a18014;
    color: #06a180 ;
    font-weight: 600;
    font-size: 12px;
  }
  .cafe-pay-button button {
    background: #06a180 ;
    border-radius: 5px;
    border: 0;
    padding: 4px 80px 4px 80px;
    color: #fff;
  }
  .close-cart {
    position: absolute;
    right: -7px;
    top: -15px;
    font-size: 25px;
    color:#06a180;
    cursor: pointer;
  }
  .orderlist .product-title {
    color: rgb(25, 27, 34);
    font-weight: 700;
    /* min-width: 151px; */
    overflow: hidden;
    display: -webkit-box;
    width: 86%;
    height: 20px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 10px;
  }
}

.height-200px {
  height: 100px;
}

.custom-col-1 {
  flex: 0 0 auto;
  width: 12.11111%;
}
.custom-del-col-1 {
  width: 3.11111%;
}
.btn-libility-primary {
  color: #000;
  background-color: #d9d8d8;
  border-color: #ffffff;
  box-shadow: none;
  /* transition: background-color 1s ease-out 50ms; */
  border-radius: 2px !important;
  font-size: 14px;
  padding: 6px 6px 6px 6px;
}
.remove-form-button {
  border: 0;
  border-radius: 5px;
  font-size: 25px;
  background: none;
  color:#06a180;
  transition: color 0.2s ease-out 50ms;
}
.remove-form-button:hover {
  color: #000;
}
.add-expense-table thead {
  background:#06a180;
  color: #fff;
}
.add-expense-table select.form-select,
.add-expense-table .form-control {
  border: 0;
}
.adj-hight.position-relative .add-expense-table select.form-select,
.adj-hight.position-relative .add-expense-table .form-control {
  font-size: 14px;
}
.add-expense-table {
  border-color: #b8b8b8 !important;
}
.add-expense-table thead th {
  padding: 10px;
  background:#06a180;
  color: #fff;
}

/* meal plan css  */
span.weekNo {
  display: block;
  margin: 0 auto;
  text-align: center;
  transform: rotate(270deg);
  font-size: 16px;
  font-weight: 600;
}
.meal-field {
  resize: none;
  height: 90px;
  width: 160px;
}
span.timeSelected-span {
  display: inline-block;
  width: max-content;
}

.time-allocated {
  display: block;
  width: 700px;
  white-space: break-spaces;
}
.detail-for-pkg {
  background: #f3f8fb;
  color: black;
  opacity: 1;
}
.detail-for-pkg-arrow {
  background: black;
}

.headings {
  width: 140px;
}
.headings-dairy {
  width: 100%;
  padding: 10px;
}
.data-dairy {
  width: calc(100% - 140px);
  display: flex;
  position: relative;
  z-index: 11;
}
.data-dairy .data-row {
  /* min-width: 160px; */
  text-align: center;
  /* padding: 0px 5px; */

  font-size: 13px;
}
.data-dairy .data-row .data-cell {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  padding: 10px 5px;
}

.table-container-dairy {
  display: flex;
  overflow: auto;
  position: relative;
}

.time-dairy,
.time-dairy .headings-dairy {
  background-color:#06a180 !important;
  color: white !important;
  font-weight: 600;
}

.client-dairy,
.time-dairy .headings-dairy {
  background-color: #fde8ec !important;
  font-weight: 600;
}
.headings-dairy,
.data-dairy .data-row .data-cell {
  min-height: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.headings {
  position: sticky;
  left: 0;
  z-index: 21;
}

.headings-dairy {
  justify-content: flex-start;

  background-color: white;
}
.table-container-dairy:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 41px;
  background:#06a180;
  z-index: 0;
}
.table-container-dairy::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 41px;
  height: 41px;
  background: #fde8ec;
  z-index: 0;
}
.grey-for-bg {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0px;
  height: 41px;
  background: #f8f9fa;
  z-index: 0;
}
.table-container-dairy .grey-for-bg.first {
  bottom: auto;
  top: 123px;
}
.show-slots {
  min-height: 41px;
  width: 160px;
  padding: 10px;
}

.slot-detail {
  display: flex;
  justify-content: space-between;
}

.attendence-btn {
  color: white;
  background-color:#06a180;
  border: none;
  border-radius: 50%;
  height: 28px;
  width: 28px;
}
.attendence-btn.pre {
  background-color: green;
}

.new-staff-name{
  font-size: 16px;
}
.new-profile-btn{
  border: none;
    border-radius: 70px;
    padding: 5px 30px;
    background-color: #f3f8fb;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
}
.new-profile-btn:hover{
  background-color:#06a180;
  color: #fff;
}


.profcard {
  width: 350px;
  height: 420px;
  border-radius: 10px;
  text-align: center;
  user-select: none;
  background: #f3f8fb;
}

.cover-photo {
  position: relative;
  background: url(../public/assets/images/prof-bg.png);
  background-size: cover;
  height: 180px;
  border-radius: 10px 10px 0 0;
}

.profile {
  position: absolute;
  width: 130px;
  bottom: -60px;
  left: 110px;
  border-radius: 50%;
  /* border: 1px solid #fff; */
  background: #fff7f7;
  padding: 5px;
}
.about {
  margin-top: 70px;
  line-height: 1.6;
  font-size: 18px;
  font-weight: 700;
}
.sub-about{
  font-weight: 100;
  font-size: 14px;
}
.prof-progress{
      padding: 0px 20px 20px 20px;
}
.sect-1{
  padding: 5px 20px;
}
.prof-avatar-edit{
  position: absolute;
  right: 114px;
  z-index: 1;
  top: 103px;
}
.prof-avatar-edit input{
  display: none;
} 
.prof-avatar-edit input + button.btn.btn-primary {
  /* display: inline-block; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  color: #06a180 ;
  margin-bottom: 0;
  border-radius: 100% !important;
  background: #ffffff;
  border: 1px solid #d2d2d2;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  /* transition: all 0.2s ease-in-out; */
}
.prof-avatar-edit input + button.btn.btn-primary:hover {
  background:#06a180;
  border-color:#06a180;
  color: #fff;
}
.prof-avatar-preview {
  /* width: 192px; */
  height: 130px;
  /* position: relative; */
  position: absolute;
  width: 130px;
  bottom: -60px;
  left: 110px;
  border-radius: 100%;
  border: 4px solid #d2d2d2;
  box-shadow: 0 0.25rem 0.75rem rgb(18 38 63 / 8%);
}
.prof-avatar-preview > div {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.new-attendance-head{
  padding: 15px 20px;
  border-right: 1px solid #dddd;
  min-width: 380px;
}
.new-attendance-head2{
  color: #384053;
  min-width: 225px;
  padding: 18px;
  border-right: 1px solid #dddd;
}

.new-attendance-head .head-icon{
  background-color: #384053;
  padding: 20px;
  height: 60px;
  width: 60px;
  border-radius: 50px;
  align-items: center;
  display: flex;
  justify-content: center;
  color: #ffff;
}
.first-heading{
  color: #384053;
  font-weight:600;
}
.first-heading-p{
  font-size: 11px;
}

.attendance-summary-head{
  box-shadow: 0 0.25rem 0.75rem rgb(18 38 63 / 8%)
}
.attendance-heading{
  color: #384053;
}
.attendance-heading-p{
  font-size: 13px;
}
.employe-attendance-export{
  border: 0;
  padding: 17px;
  border-radius: 5px;
  background-color: #384053;
  color: #ffff;
  align-items: center;
  display: flex;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
}
.employe-attendance-export:hover{
  background:#06a180;
}
.line-sepration{
  height: 1px;
    width: 100%;
    background: #dddd;
}
.employee-attendance-filter{
  border: 1px solid #ddd;
  min-width: 100px;
  padding: 8px;
  background: transparent;
  border-radius: 5px;
  color: #384053;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,

}
.employee-attendance-filter:hover{
  background-color: #384053;
  color: #fff;
}